<div
  class="card default-card food1-card-produto-carrinho {{config.propsTemplate?.menu?.size?.toLowerCase()}}"
>
  <div class="card-header">
    <h5 class="card-title">{{pedidoProduto.produto?.nome || '--'}}</h5>
  </div>
  <div class="row g-0">
<!--    <input hidden type="checkbox" [checked]="false" #inputShowFullComplementos>-->
    <div class="content-image order-0 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
      <img
        [src]="pedidoProduto?.produto?.imageUrl || '/assets/img/default-image.jpg'"
        srcset="assets/img/default-image.jpg"
        class="img-fluid"
        alt="..."
        [class.rounded]="config.propsTemplate?.produto?.img?.format != 'ROUND'"
      >
    </div>
    <div
      class="content-body order-2 order-xl-1 order-lg-1 order-md-2 d-flex flex-column col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12"
    >
      <div class="row">
        <div class="col-12">
          <ul class="list-group list-complementos" style="padding: 0 .5rem">
            <li
              *ngFor="let itemComplemento of (pedidoProduto.complementosPedido || [])"
              class="list-group-item"
            >
              <div class="row">
                <div class="col-12">
                  {{itemComplemento.nome}}
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ul class="list-group list-complementos">
                    <li
                      *ngFor="let itemItem of (itemComplemento.itemsPedido || [])"
                      class="list-group-item"
                      style="font-weight: 400"
                    >
                      {{itemItem.nome}}
                    </li>

                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>
    <div class="content-actions order-1 order-xl-1 order-lg-2 order-md-1 col-xl-4 col-lg-4 col-md-9 col-sm-9 col-9">
      <div class="row">
        <div class="col-input col-xl-12 col-lg-12 col-md-6 col-sm-6 col-7">
          <label class="form-label fs-10 f-bold text-center w-100">Quantidade</label>
          <div class="input-group mb-3 w-100">
            <span (click)="changeQuantidadeProduto(pedidoProduto.quantidade-1)" class="input-group-text">-</span>
            <input type="number" class="form-control" [(ngModel)]="pedidoProduto.quantidade">
            <span (click)="changeQuantidadeProduto(pedidoProduto.quantidade+1)" class="input-group-text">+</span>
          </div>
        </div>
        <div class="col-cations col-xl-12 col-lg-12 col-md-6 col-sm-6 col-5 p-0 m-0">
          <div class="col-xl-12 col-lg-12 col-md-6 col-sm-6 col-12">
            <label class="form-label text-center w-100">Valor unitário</label>
            <p class="text-muted text-center">R$ {{pedidoProduto?.produto?.valor || 'Error'}}</p>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-6 col-sm-6 col-12">
            <label class="form-label text-center w-100">Subtotal</label>
            <p class="text-muted text-center">R$ {{pedidoProduto.valor}}</p>
          </div>
        </div>
      </div>


<!--      <div class="row">-->
<!--        <div class="col-12">-->
<!--          <p style="text-align: center; font-weight: 500; font-size: 11pt">R$ {{pedidoProduto.valor}}</p>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row justify-content-center">-->
<!--        <div class="col-xl-12 col-lg-12 col-md-6 col-sm-8 col-10">-->
<!--          <div class="input-group mb-3 w-100">-->
<!--            <span (click)="changeQuantidadeProduto(pedidoProduto.quantidade-1)" class="input-group-text">-</span>-->
<!--            <input type="number" class="form-control" [(ngModel)]="pedidoProduto.quantidade">-->
<!--            <span (click)="changeQuantidadeProduto(pedidoProduto.quantidade+1)" class="input-group-text">+</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="row">-->
<!--        <div class="col-xl-12 col-lg-12 col-md-6 col-sm-6 col-6 d-inline-flex justify-content-center">-->
<!--          <button class="m-1 w-100 btn btn-sm btn-light">Editar</button>-->
<!--        </div>-->
<!--        <div class="col-xl-12 col-lg-12 col-md-6 col-sm-6 col-6 d-inline-flex justify-content-center">-->
<!--          <button class="m-1 w-100 btn btn-sm btn-light" style="color: red">Remover Item</button>-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
<!--  <div *ngIf="(pedidoProduto?.complementosPedido || []).length > 0 && getWidth < 768" class="col-12 d-inline-flex justify-content-center">-->
<!--    <span *ngIf="!inputShowFullComplementos.checked" (click)="inputShowFullComplementos.click()" class="material-symbols-outlined">stat_minus_1</span>-->
<!--    <span *ngIf="inputShowFullComplementos.checked" (click)="inputShowFullComplementos.click()" class="material-symbols-outlined">keyboard_arrow_up</span>-->
<!--  </div>-->
  <div class="card-footer d-inline-flex">
    <button class="m-1 ms-auto w-100 btn btn-sm btn-light">Editar</button>
    <button class="m-1 ms-auto w-100 btn btn-sm btn-light" style="color: red">Remover</button>
  </div>
</div>

