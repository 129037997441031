import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {WebsecketService} from "../../../../services/websocket/websecket.service";
import {HttpService} from "../../../../services/http-service/http.service";
import {VENDAS_PEDIDO} from "../../../../core/config/endpoints";
import {VendaEntity} from "../../../../entitys/venda.entity";
import {SessionService} from "../../../../services/session-service/session.service";

@Component({
  selector: 'app-food1-acompanhar-pedido',
  templateUrl: './food1-acompanhar-pedido.component.html',
  styleUrls: ['./food1-acompanhar-pedido.component.scss', '../../scss/index.scss']
})
export class Food1AcompanharPedidoComponent implements OnInit, OnDestroy {

  venda: VendaEntity = new VendaEntity();
  private notificationSound = new Audio('assets/sounds/notification-2.wav');

  constructor(
    private actRouter: ActivatedRoute,
    private socketService: WebsecketService,
    private http: HttpService,
    public session: SessionService
  ) {
    if (actRouter.snapshot.params['id']) {
      http.get(VENDAS_PEDIDO+'/'+actRouter.snapshot.params['id']).subscribe(resp => {
        this.venda = resp.data;
        socketService.socket.on('acompanhar-pedido-'+actRouter.snapshot.params['id'], resp => {
          console.log(resp)
          this.notificationSound?.play().then()
          this.venda.status = resp.status;
        })
      })
    }

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.socketService.socket.off('acompanhar-pedido-'+this.venda.id)
  }

}
