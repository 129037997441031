import { Injectable } from '@angular/core';
import { Socket, io } from 'socket.io-client';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class WebsecketService {
  public socket!: Socket;

  constructor() {
    this.connect();
  }

  public connect() {
    this.socket = io(environment.apiUrlMock,{ transports: ['websocket'] });
    this.socket.on('connect', () => {
      console.log('connected socket.io')
    });
  }

  public send(args: any) {
    this.socket.send(args)
  }

  public disconnect() {
    this.socket.disconnect();
  }
}
