<div
  class="card default-card food1-card-produto menu-principal {{config.propsTemplate?.menu?.size?.toLowerCase()}}"
  routerLink="produto/{{produto.id}}"
>
  <div class="row g-0">
    <div
      class="content-image"
      [class.order-1]="config.propsTemplate?.menu?.direction == 'END'"
      [class.order-0]="config.propsTemplate?.menu?.direction == 'START'"
      [class.col-3]="config.propsTemplate?.menu?.size == 'SM'"
      [class.col-4]="config.propsTemplate?.menu?.size == 'MD'"
      [class.col-5]="config.propsTemplate?.menu?.size == 'LG'"
    >
      <img
        [src]="produto?.imageUrl || '/assets/img/default-image.jpg'"
        class="img-fluid"
        alt="..."
        [class.circle]="config.propsTemplate?.produto?.img?.format == 'ROUND'"
      >
    </div>
    <div
      class="d-flex flex-column"
      [class.order-0]="config.propsTemplate?.menu?.direction == 'END'"
      [class.order-1]="config.propsTemplate?.menu?.direction == 'START'"
      [class.col-9]="config.propsTemplate?.menu?.size == 'SM'"
      [class.col-8]="config.propsTemplate?.menu?.size == 'MD'"
      [class.col-7]="config.propsTemplate?.menu?.size == 'LG'"
    >
      <div class="card-body">
        <h5 class="card-title">{{produto?.nome || '--'}}</h5>
        <p class="card-text">{{produto?.descricao}}</p>
      </div>
      <div class="card-footer mt-auto">
        <p class="card-text"><small class="text-muted">R$ {{produto.valor}}</small></p>
      </div>
    </div>
  </div>
</div>
