import {NgModule} from '@angular/core';
import {Router, RouterModule, Routes} from '@angular/router';
import {SharedLoadEmpresaComponent} from "./shared-pages/shared-load-empresa/shared-load-empresa.component";
import {LayoutGuard} from "./core/guards/layout.guard";
import {InitLayoutService} from "./services/init-layout-service/init-layout.service";
import {environment} from "../environments/environment";
import {HomeComponent} from "./shared-pages/home/home.component";
import {StartEditorModeComponent} from "./shared-pages/start-editor-mode/start-editor-mode.component";

/**
 * Função para observar o production no environment e determinar se apresenta
 * as rotar de desenvolvimento ou de produção
 */
function routersSelect(): Routes {
  if (environment.production) {
    return [
      {path: 'client/:nomeEmpresa', component: SharedLoadEmpresaComponent},
      {path: '', component: SharedLoadEmpresaComponent},
    ];
  }

  return [
    { path: 'client/:nomeEmpresa', component: SharedLoadEmpresaComponent },
    { path: 'client/:nomeEmpresa/', component: SharedLoadEmpresaComponent},
    // Essa palhaçada aqui abaixo é para capturar todas as rotas e mandar para o loader
    // poder fazer o carregamento da empresa e retornar para a página do site.
    { path: 'client/:nomeEmpresa/:v1', component: SharedLoadEmpresaComponent},
    { path: 'client/:nomeEmpresa/:v1/:v2', component: SharedLoadEmpresaComponent},
    { path: 'client/:nomeEmpresa/:v1/:v2/:v3', component: SharedLoadEmpresaComponent},
    { path: 'client/:nomeEmpresa/:v1/:v2/:v3/:v4', component: SharedLoadEmpresaComponent},
    { path: 'client/:nomeEmpresa/:v1/:v2/:v3/:v4/:v5', component: SharedLoadEmpresaComponent},
    { path: 'client/:nomeEmpresa/:v1/:v2/:v3/:v4/:v5/:v6', component: SharedLoadEmpresaComponent},
    { path: 'client/:nomeEmpresa/:v1/:v2/:v3/:v4/:v5/:v6/:v7', component: SharedLoadEmpresaComponent},
    { path: '', component: HomeComponent }
  ];
}

const routes: Routes = routersSelect();

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router, private templateService: InitLayoutService) {

    const newRouters = templateService.verifySetRouters()

    if (!!newRouters) {
      this.router.resetConfig([
        newRouters
      ]);
    }
  }
}
