<layout-food1-v2>
  <div editor-content *ngIf="config.propsTemplate">
    <div class="accordion" id="accordionExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="headingCardaio">
          <button
            class="accordion-button collapsed"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseCardaio"
            aria-expanded="false"
            aria-controls="collapseCardaio"
          >
            Cardápio
          </button>
        </h2>
        <div id="collapseCardaio" class="accordion-collapse collapse" aria-labelledby="headingCardaio" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <div class="row">
              <div class="col-12">
                <label class="form-label">Direção da imagem</label>
                <select [(ngModel)]="config.propsTemplate['menu']['direction']" changeDetectPropsDesign class="form-select form-select-sm">
                  <option value="START">Direita</option>
                  <option value="END">Esquerda</option>
                </select>
              </div>
              <div class="col-12">
                <label class="form-label">Formato da imagem</label>
                <select [(ngModel)]="config.propsTemplate['produto']['img']['format']" changeDetectPropsDesign class="form-select form-select-sm">
                  <option value="ROUND">Circular</option>
                  <option value="SQUARE">Quadrada</option>
                </select>
              </div>
              <div class="col-12">
                <label class="form-label">Tamanho do card</label>
                <select [(ngModel)]="config.propsTemplate['menu']['size']" changeDetectPropsDesign class="form-select form-select-sm">
                  <option value="LG">Grande</option>
                  <option value="MD">Média</option>
                  <option value="SM">Pequena</option>
                </select>
              </div>
              <div class="col-12">
                <label class="form-label">Produtos por linha</label>
                <select [(ngModel)]="config.propsTemplate['menu']['elementosPorLinha']" changeDetectPropsDesign class="form-select form-select-sm">
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="basic-page-cardapio-produtos"  class="container-fluid">
    <div class="row">
      <div *ngFor="let item of listProdutos" class="{{getColClass()}} mb-2">
        <food1-card-produto-menu1
          style="min-height: 100%"
          [produto]="item"
        ></food1-card-produto-menu1>
      </div>
    </div>
  </div>
</layout-food1-v2>
