import {setConfigDynamicTable} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../environments/environment";

export const configGlobalDynamicTable: setConfigDynamicTable = {
  apiData: {
    path: environment.apiUrl,
    context: '',
    inputPipe: (resp: any) => {
      return resp['data'] || []
    },
    inputPaginationPipe: (p:any) => {
      return {
        page: p.pagination.number,
        totalPages: p.pagination.totalPages,
        totalElements: p.pagination.totalElements
      };
    },
    outputPaginationPipe: (pageable) => {
      const {totalElements, totalPages, typePagination, ...rest} = pageable;
      return rest;
    }
  },
}
