import {ProdutoComplementosEntity} from "./produto-complemento.entity";
import {PedidoProdutoComplementoItemEntity} from "./pedido-produto-complemento-item.entity";


export class PedidoProdutoComplementoEntity {
  constructor(
    id?: string | number,
    nome?: string,
    quantidade?: number,
    complemento?: ProdutoComplementosEntity,
    itemsPedido?: PedidoProdutoComplementoItemEntity[]
  ) {
    this.id = id;
    this.nome = nome;
    this.quantidade = quantidade;
    this.complemento = complemento;
    this.itemsPedido = Array.isArray(itemsPedido) ? itemsPedido : [];
  }
  id: string | number | undefined
  nome: string | undefined
  quantidade: number | undefined
  complemento: ProdutoComplementosEntity | undefined
  itemsPedido: PedidoProdutoComplementoItemEntity[] = []
}
