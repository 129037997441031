<div class="card card-endereco">
  <div class="row g-0">
    <div class="col-2 col-icon">
      <span class="material-symbols-outlined fs-30" [class.active]="active">person_pin_circle</span>
    </div>
    <div class="col-9 col-body">
      <div class="card-body">
        <h6 class="card-title">
          {{endereco?.rua}} {{endereco?.numero}}, {{endereco?.complemento}}
        </h6>
        <span class="card-text">Rio de janeiro | Penha</span>
      </div>
    </div>
    <div class="col-1 col-actions" *ngIf="showActions">
      <span (click)="modalEditar.open(endereco)" class="material-symbols-outlined">edit</span>
      <span class="material-symbols-outlined">delete</span>
    </div>
  </div>
</div>

<modal-endereco #modalEditar></modal-endereco>
