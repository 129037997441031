import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Food1HomeComponent} from './pages/food1-home/food1-home.component';
import {LayoutFood1Component} from './layout/layout-food1/layout-food1.component';
import {RouterModule} from "@angular/router";
import {Food1CategoriasComponent} from './pages/food1-categorias/food1-categorias.component';
import {Food1CategoriasMainComponent} from './pages/food1-categorias-main/food1-categorias-main.component';
import {Food1ProdutoComponent} from './pages/food1-produto/food1-produto.component';
import {Food1CarrinhoComponent} from './pages/food1-carrinho/food1-carrinho.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxMaskModule} from "ngx-mask";
import {Food1AcompanharPedidoComponent} from './pages/food1-acompanhar-pedido/food1-acompanhar-pedido.component';
import {SharedModule} from "./shared/shared.module";
import {UiModule} from "../../shared/ui/ui.module";
import {Food1ProdutosModule} from "./shared/food1-produtos/food1-produtos.module";
import {UpdatePropsDesignModule} from "../../directives/update-props-design/update-props-design.module";
import {MatStepperModule} from "@angular/material/stepper";
import {ModalsModule} from "../../shared/modal/modals.module";
import { LayoutFood1V2Component } from './layout/layout-food1-v2/layout-food1-v2.component';
import {DgModalModule} from "@datagrupo/dg-ng-util";


@NgModule({
  declarations: [
    Food1HomeComponent,
    LayoutFood1Component,
    Food1CategoriasComponent,
    Food1CategoriasMainComponent,
    Food1ProdutoComponent,
    Food1CarrinhoComponent,
    Food1AcompanharPedidoComponent,
    LayoutFood1V2Component,
  ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        NgxMaskModule,
        FormsModule,
        SharedModule,
        UiModule,
        Food1ProdutosModule,
        UpdatePropsDesignModule,
        MatStepperModule,
        ModalsModule,
        DgModalModule
    ]
})
export class TemplateFood1Module {
}
