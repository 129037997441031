<div class="container-fluid" *ngIf="!!pedidoProduto">
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <button class="btn btn-secondary m-1" (click)="backStatus()">Voltar</button>
      <button class="btn btn-info m-1" [disabled]="verifyDisabledButtonNext()" *ngIf="statusSelecao != 'observacao'" (click)="nextStatus()">Avançar</button>
      <button class="btn btn-success m-1" *ngIf="statusSelecao == 'observacao'" (click)="finalizar.emit()">Finalizar</button>
    </div>
  </div>

  <div class="row mt-1" *ngFor="let itemComplemento of (pedidoProduto.complementosPedido || []); let indexComplemento = index">
    <div class="col-12" *ngIf="statusSelecao == 'complementos'">
      <div class="card default-card">
        <div class="card-header">
          <h5 class="card-title">{{itemComplemento.nome}}</h5>
          <p class="card-subtitle">um pequeno texto descritivo</p>
          <p *ngIf="!!itemComplemento?.complemento?.limit" class="card-subtitle text-muted">Selecione até {{itemComplemento?.complemento?.limit}} itens</p>
        </div>
        <div class="card-body p-0">
          <ul class="list-group list-produto-comlementos">
            <li
              *ngFor="let item of (itemComplemento?.itemsPedido || []); let indexItem = index"
              class="list-group-item"
            >
              <div class="row">
                <div class="col-12 d-inline-flex justify-content-between">

                  <div *ngIf="itemComplemento.complemento?.tipoSelecao == 'UNITARIO'" class="col col-auto d-flex flex-column justify-content-center align-items-center">
                    <div (click)="realInput.click()">
                      <input [type]="produtoService.getTypeInputIten(itemComplemento)" [checked]="realInput.checked">
                      <input #realInput hidden type="checkbox" (ngModelChange)="verifySelectedItem($event, indexComplemento, indexItem)" [(ngModel)]="item.selected">
                    </div>
                  </div>

                  <div *ngIf="itemComplemento.complemento?.tipoSelecao == 'MULTIPLO'" class="col col-auto d-flex flex-column justify-content-center align-items-center p-2">
                    <div class="input-group">
                      <span (click)="subtraiQuantidade(indexComplemento, indexItem)" class="input-group-text">-</span>
                      <div class="form-control d-inline-flex justify-content-center align-items-center">{{item.quantidade}}</div>
                      <span (click)="addQuantidade(indexComplemento, indexItem)" class="input-group-text">+</span>
                    </div>
                    <span *ngIf="!!item?.item?.limit" class="text-muted fs-10">Max: {{item?.item?.limit}}</span>
                  </div>

                  <div class="col d-flex align-items-center p-2 justify-content-start">
                    <div (click)="itemComplemento.complemento?.tipoSelecao == 'MULTIPLO' ? null : item.selected = true; verifySelectedItem(true, indexComplemento, indexItem);">
                      <span class="f-bold">{{item?.nome}}</span>
                      <br>
                      <span class="text-muted fs-10">Algum texto descritivo</span>
                    </div>
                  </div>

                  <div class="col d-flex align-items-center p-2 justify-content-end">
                    <p class="text-muted" *ngIf="!!item?.item?.custoAdicional">+ R$: {{item?.item?.custoAdicional}}</p>
                    <p class="text-muted" *ngIf="!item?.item?.custoAdicional">Sem custo</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="rowm mt-1">
    <div class="col-12" *ngIf="statusSelecao == 'bebidas'">
      <div class="card default-card">
        <div class="card-header">
          <h5 class="card-title">Observação</h5>
        </div>

      </div>
    </div>
  </div>

  <div class="rowm mt-1">
    <div class="col-12" *ngIf="statusSelecao == 'observacao'">
      <div class="card default-card">
        <div class="card-header">
          <h5 class="card-title">Observação</h5>
        </div>
        <div class="card-body">
          <label class="form-label">Alguma observação sobre esse pedido?</label>
          <textarea class="form-control" rows="6" placeholder="Suas observações aqui..."></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
