<div class="card default-card card-cart" style="position: sticky; top: 10px">
  <div class="card-header">
    <h5 class="card-title">Carrinho</h5>
    <p class="card-subtitle" *ngIf="!!session.user.id">Olá {{session?.user?.nome}}</p>
  </div>
  <div class="card-body">
    <table class="table-produtos">
      <tbody>
        <tr *ngFor="let item of carrinho.values.produtos">
          <td>
            <span class="f-bold text-muted">{{item?.nome}}</span>
            <ul class="list-group list-complementos">
              <li *ngFor="let itemComplemento of item.complementosPedido" class="list-group-item">
                <ul class="list-group list-complementos">
                  <li *ngFor="let itemPedido of itemComplemento.itemsPedido" class="list-group-item">
                    {{itemPedido?.nome}}
                  </li>
                </ul>
              </li>
            </ul>
          </td>
          <td class="total">R$ {{item?.valor}}</td>
        </tr>
        <tr>
          <td>Total:</td>
          <td class="total">
            R$ {{carrinho.values.total || '0,00'}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="card-footer d-inline-flex justify-content-center">
    <button
      [disabled]="carrinho.values.produtos.length < 1" (click)="link_carrinho()"
      class="btn btn-success"
    >
      Ver carrinho e finalizar pedido
    </button>
  </div>
</div>
