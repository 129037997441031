import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sidebar-editor-mode',
  templateUrl: './sidebar-editor-mode.component.html',
  styleUrls: ['./sidebar-editor-mode.component.scss']
})
export class SidebarEditorModeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  isSidebarVisible: boolean = false; // Estado da sidebar

  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible; // Alterna a visibilidade
  }

}
