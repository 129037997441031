import { Injectable } from '@angular/core';
import {AbstractSessionService} from "@datagrupo/dg-ng";
import {TokenService} from "../token-service/token.service";
import {ClienteEntity} from "../../entitys/cliente.entity";

// interface para os dados da empresa
export declare type rootEmpresa = {
  id: string,
  nome: string,
  template: string,
  link: string,
  formasPagamento: ('DINHEIRO' | 'PIX' |  'CARTAO')[],
  logoImgUrl?: string,
  descricao?: string,
  logradouro?: string,
  numero?: string,
  bairro?: string,
  cidade?: string,
  estado?: string,

  domingoAbertura?: string;
  domingoFechamento?: string;
  segundaAbertura?: string;
  segundaFechamento?: string;
  tercaAbertura?: string;
  tercaFechamento?: string;
  quartaAbertura?: string;
  quartaFechamento?: string;
  quintaAbertura?: string;
  quintaFechamento?: string;
  sextaAbertura?: string;
  sextaFechamento?: string;
  sabadoAbertura?: string;
  sabadoFechamento?: string;

}
// interface para dados do cliente
abstract class UserSessionEntity {
  id?: string
  nome?: string
  perfil?: string
}
// interface para os dados do expediente
export interface Expediente {
  "id": string,
  "createdAt": string,
  "deleteAt": string | null,
  "dataInicio": string,
  "dataFim": string | null
}

declare type tipoPerfil = 'ESTABELECIMENTO' | 'CLIENTE'

@Injectable({
  providedIn: 'root'
})
export class SessionService extends AbstractSessionService {

  /**
   * dados do cliente
   */
  user: UserSessionEntity = {};
  /**
   * Guarda os dados do cliente atual
   */
  expediente: Expediente | null = null;
  /**
   * guarda os dados da empresa atual
   */
  rootEmpresa: rootEmpresa | undefined;
  /**
   * Guarda a base da url atual. Isso é necessário, pois a URL é dinamica
   * e muda conforme o nome da empresa.
   */
  baseRouter: string = '';

  constructor(public token: TokenService) {
    super(token)
  }

  setUser(): void {
    let token = this.token.abrirToken();
    this.user.id = token.sub;
    this.user.nome = token.name;
    this.user.perfil = token.perfil;
  }

  setExpediente(expediente: Expediente):void {
    this.expediente = expediente;
  }

  override logout() {
    window.localStorage.clear();
    window.location.reload();
  }

  override checkPerfil(perfil: tipoPerfil | tipoPerfil[], perfilAttributeToken?: string): boolean {
    return super.checkPerfil(perfil, 'perfil');
  }
}
