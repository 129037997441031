import {AbstractTemplateEntity, ImageTemplateEntity} from "./abstract-template.entity";


export class TemplateFood1PropsEntity extends AbstractTemplateEntity {
  getListUpdateVariablesCss(): [string, string][] {
    return [
      ['--food1-header-font-color', this.header.fontColor],
      ['--food1-header-bg', this.header.background],
      ['--food1-header-font-color-active', this.header.fontColorActive],
    ]
  }

  constructor(
    props: {
      id?: string,
      nome?: string,
      mapImg?: ImageTemplateEntity[],
    } & { [key: string]: any }
  ) {
    super(props.id, props.nome, props.mapImg);

    if (!props) props = {};

    if ('produto' in props) {
      if ('img' in props['produto']) {
        if ('format' in props['produto']['img']) {
          this.produto.img.format = props['produto']['img']['format'];
        }
      }
    }

    if ('menu' in props) {
      if ('direction' in props['menu']) this.menu.direction = props['menu']['direction'];
      if ('size' in props['menu']) this.menu.size = props['menu']['size'];
      if ('elementosPorLinha' in props['menu']) this.menu.elementosPorLinha = props['menu']['elementosPorLinha'];
    }

    if ('header' in props) {
      this.header = {
        ...this.header,
        ...props['header']
      }
    }
  }

  header: {
    padding: 'SM' | 'MD' | 'LG',
    background: string,
    fontColor: string,
    fontColorActive: string,
  } = {
    padding: 'SM',
    background: '#ffffff',
    fontColor: '#000000',
    fontColorActive: '#818181',
  }

  produto: {
    img: {
      format: "SQUARE" | 'ROUND',
    }
  } = {
    img: {
      format: "SQUARE"
    }
  };

  menu: {
    direction: 'START' | 'END',
    size: 'SM' | 'MD' | 'LG',
    elementosPorLinha: '1' | '2'
  } = {
    direction: 'START',
    size: 'MD',
    elementosPorLinha: '2'
  };

}
