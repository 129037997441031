import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from "@angular/forms";

type formasPagamentoDTO = 'DINHEIRO' | 'PIX' | 'CARTAO';

@Component({
  selector: 'card-forma-pagamento',
  templateUrl: './card-forma-pagamento.component.html',
  styleUrls: ['./card-forma-pagamento.component.scss']
})
export class CardFormaPagamentoComponent implements OnInit {

  @Output('clickSelect') clickSelect = new EventEmitter<formasPagamentoDTO>()

  @Input('tipo-pagamento') tipoPagamento: formasPagamentoDTO = 'DINHEIRO';
  @Input('selected') selected: boolean = false;
  @Input("mode") mode: 'show' | 'selectec' = 'show'

  imgFormaPagamento = {
    DINHEIRO: '/assets/img/formas-pagamento/dinheiro.png',
    PIX: '/assets/img/formas-pagamento/pix.png',
    CARTAO: '/assets/img/formas-pagamento/cartoes.png',
  }

  formaPagamentoSelecionada = new FormControl('DINHEIRO')

  constructor() { }

  ngOnInit(): void {
    this.formaPagamentoSelecionada.patchValue(this.tipoPagamento);
  }

}
