import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {DgModalComponent} from "@datagrupo/dg-ng-util";
import {HttpService} from "../../../../../services/http-service/http.service";
import {ENDERECO} from "../../../../../core/config/endpoints";
import {ClienteEnderecoEntity} from "../../../../../entitys/cliente-endereco.entity";
import {SessionService} from "../../../../../services/session-service/session.service";

@Component({
  selector: 'modal-selecionar-endereco',
  templateUrl: './modal-selecionar-endereco.component.html',
  styleUrls: ['./modal-selecionar-endereco.component.scss']
})
export class ModalSelecionarEnderecoComponent implements OnInit {

  @ViewChild('modal') modal!: DgModalComponent;
  @Output('afterSave') afterSave = new EventEmitter<ClienteEnderecoEntity>()

  listEnderecos: ClienteEnderecoEntity[] = [];
  enderecoSelecionado: ClienteEnderecoEntity | undefined;

  constructor(private http: HttpService, private session: SessionService) {}

  ngOnInit(): void {
  }

  open() {
    debugger
    this.http.get(ENDERECO, { params: { unpaged: true } }).subscribe(resp => {
      this.listEnderecos = resp.data;
      this.modal.open()
    })
  }

  close() {
    this.modal.close()
  }

  clear() {
    this.enderecoSelecionado == undefined
  }

  save() {
    if (!this.enderecoSelecionado) return;

    this.afterSave.emit(this.enderecoSelecionado)
    this.close()
  }

  selecionarEndereco(endereco: ClienteEnderecoEntity, dbClick = false) {
    this.enderecoSelecionado = endereco;

    if (dbClick) this.save();
  }
}
