import {Component, Input, OnInit} from '@angular/core';
import {
  ConfigDesignLayoutService
} from "../../../../services/config-design-layout-service/config-design-layout.service";
import {TemplateFood1PropsEntity} from "../../../../entitys/template-entitys/template-food1.props.entity";
import {SessionService} from "../../../../services/session-service/session.service";
import {replaceEndpointParams} from "../../../../helpers/endpoints-helpers";
import {CATEGORIA, ROOT_ESTABELECIMENTO, TEMPLATE, TEMPLATE_IMG} from "../../../../core/config/endpoints";
import {HttpService} from "../../../../services/http-service/http.service";

@Component({
  selector: 'layout-food1-v2',
  templateUrl: './layout-food1-v2.component.html',
  styleUrls: ['./layout-food1-v2.component.scss', '../../scss/index.scss']
})
export class LayoutFood1V2Component implements OnInit {
  @Input() showCarrinho: boolean = true;
  @Input() showMenu: boolean = true;

  isSidebarExpanded: boolean = false;
  listCategorias: any[] = [];
  editorMode: boolean;
  file: File | undefined;

  descricaoEmpresa?: string;
  logoEmpresa?: string;

  constructor(
    public config: ConfigDesignLayoutService<TemplateFood1PropsEntity>,
    public session: SessionService,
    private http: HttpService,
  ) {
    this.editorMode = config.modoEditor;
    http.get(CATEGORIA).subscribe(resp => {
      this.listCategorias = resp.data;
    })
    http.get('empresa/expediente').subscribe(resp => {
      this.session.setExpediente(resp.data)
    })
    this.logoEmpresa = session.rootEmpresa?.logoImgUrl;
    this.descricaoEmpresa = session.rootEmpresa?.descricao;
  }

  ngOnInit(): void {
    this.http.get(replaceEndpointParams(ROOT_ESTABELECIMENTO, this.session.rootEmpresa?.link || '')).subscribe(resp => {
      if (!resp?.data) return;
      const data = resp.data;

      this.logoEmpresa = data?.logoImgUrl;
      this.descricaoEmpresa = data?.descricao;
    })
  }

  toggleSidebar() {
    this.isSidebarExpanded = !this.isSidebarExpanded;
  }

  uploadFile(event?: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }
    //@ts-ignore
    const file: File = event.target.files[0];

    if (!file) return;

    const data = new FormData();
    data.set('file', file);

    const params = [this.config.propsTemplate?.id || '', 'bannerLayout']

    this.http.post(replaceEndpointParams(TEMPLATE_IMG, params), data).subscribe(resp => {
      this.config.updateImgByPropsKey(resp.data)
      this.config.atualizarSessionStoragy();
    })
  }

  salvarPropsTemplate() {
    // @ts-ignore
    const { mapImg, ...data} = this.config.propsTemplate

    this.http.put(TEMPLATE, data).subscribe(resp => {
      this.config.atualizarSessionStoragy()
    })
  }

  pipeHorarioEmpresa(val?: string) {
    if (!val) return '';
    return val.substring(0,2) + ':' + val.substring(2)
  }

}
