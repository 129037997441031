import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardSideCarrinhoComponent } from './card-side-carrinho/card-side-carrinho.component';
import { ModalEnderecoComponent } from './modal/modal-endereco/modal-endereco.component';
import {DgModalModule} from "@datagrupo/dg-ng-util";
import {ReactiveFormsModule} from "@angular/forms";
import { CardEnderecoComponent } from './card-endereco/card-endereco.component';
import { ModalSelecionarEnderecoComponent } from './modal/modal-selecionar-endereco/modal-selecionar-endereco.component';
import { CardFormaPagamentoComponent } from './card-forma-pagamento/card-forma-pagamento.component';



@NgModule({
    declarations: [
        CardSideCarrinhoComponent,
        ModalEnderecoComponent,
        CardEnderecoComponent,
        ModalSelecionarEnderecoComponent,
        CardFormaPagamentoComponent
    ],
    exports: [
        CardSideCarrinhoComponent,
        ModalEnderecoComponent,
        ModalSelecionarEnderecoComponent,
        CardEnderecoComponent,
        CardFormaPagamentoComponent
    ],
  imports: [
    CommonModule,
    DgModalModule,
    ReactiveFormsModule
  ]
})
export class SharedModule { }
