import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'editor-mode-hover',
  templateUrl: './editor-mode-hover.component.html',
  styleUrls: ['./editor-mode-hover.component.scss']
})
export class EditorModeHoverComponent implements OnInit {

  @Input() directionMenu: 'left' | 'right' = "left";

  constructor() { }

  ngOnInit(): void {
  }

  isSidebarVisible: boolean = false; // Estado da sidebar

  toggleSidebar() {
    this.isSidebarVisible = !this.isSidebarVisible; // Alterna a visibilidade
  }
}
