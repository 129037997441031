<div class="card card-forma-pagamento" (click)="clickSelect.emit(this.tipoPagamento)">
  <div class="row g-0">
    <div *ngIf="mode == 'selectec'" class="col-input col">
      <input #fakeInput hidden [checked]="selected" disabled>
      <input [checked]="fakeInput.checked" class="form-check" type="radio">
    </div>
    <div class="col-img col" [class.m-1]="mode == 'show'">
      <img [src]="imgFormaPagamento[tipoPagamento]" class="img-fluid rounded-start" alt="Pix">
    </div>
    <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
      <div class="card-body">
        <h5 *ngIf="tipoPagamento == 'DINHEIRO'" class="card-title">Dinheiro</h5>
        <h5 *ngIf="tipoPagamento == 'PIX'" class="card-title">Pix</h5>
        <h5 *ngIf="tipoPagamento == 'CARTAO'" class="card-title">Debido/Crédito</h5>
      </div>
    </div>
  </div>
</div>
