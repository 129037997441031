<div
  class="card default-card food1-card-produto-carrinho {{config.propsTemplate?.menu?.size?.toLowerCase()}}"
>
  <div class="card-header">
    <h5 class="card-title">{{pedidoProduto.produto?.nome || '--'}}</h5>
  </div>
  <div class="row g-0">
    <div class="content-image col-2">
      <img
        [src]="pedidoProduto.produto?.imageUrl || '/assets/img/default-image.jpg'"
        class="img-fluid"
        alt="..."
        [class.rounded]="config.propsTemplate?.produto?.img?.format != 'ROUND'"
      >
    </div>
    <div class="content-body d-flex flex-column col-7">
      <div class="row">
        <div class="col-12">
          <ul class="list-group list-complementos" style="padding: 0 .5rem">
            <li
              *ngFor="let itemComplemento of (pedidoProduto.complementosPedido || [])"
              class="list-group-item"
            >
              <div class="row">
                <div class="col-12">
                  {{itemComplemento.nome}}
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <ul class="list-group list-complementos">
                    <li
                      *ngFor="let itemItem of (itemComplemento.itemsPedido || [])"
                      class="list-group-item"
                      style="font-weight: 400"
                    >
                      {{itemItem.nome}}
                    </li>

                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>
    <div class="content-actions col-3">
      <div class="row">
        <div class="col-12">
          <p style="text-align: center; font-weight: 500; font-size: 11pt">R$ {{pedidoProduto.valor}}</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="input-group mb-3 w-100">
            <span (click)="changeQuantidadeProduto(pedidoProduto.quantidade-1)" class="input-group-text">-</span>
            <input type="number" class="form-control" [(ngModel)]="pedidoProduto.quantidade">
            <span (click)="changeQuantidadeProduto(pedidoProduto.quantidade+1)" class="input-group-text">+</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 d-inline-flex justify-content-center">
          <button class="m-1 w-100 btn btn-sm btn-light">Editar</button>
        </div>
        <div class="col-12 d-inline-flex justify-content-center">
          <button class="m-1 w-100 btn btn-sm btn-light" style="color: red">Remover Item</button>
        </div>
      </div>
    </div>
  </div>
</div>

