<layout-food1-v2 [showCarrinho]="false">
  <div class="container-fluid mb-5">
    <div class="row justify-content-center stepper-container">
      <div class="col-xl-11 col-lg-11 col-mg-11 col-sm-12">
        <mat-stepper [linear]="true" #stepper>
          <mat-step [completed]="!!session.user.id">
            <ng-template matStepLabel>Seu pedido</ng-template>
            <div class="row">
              <div class="col-xl-8 col-lg-9 col-md-8">
                <div class="row mb-2">
                  <div class="col-12">
                    <div class="card default-card">
                      <div class="card-header">
                        <h4 class="card-title">Pedido</h4>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-2 justify-content-center" *ngFor="let itemProduto of (carrinho.values.produtos || []); let indexProduto = index">
                  <div class="col-11">
                    <food1-produtos-carrinho1 [pedidoProduto]="itemProduto" [indexPedidoProduto]="indexProduto">
                    </food1-produtos-carrinho1>
                  </div>
                </div>




                <div class="card default-card">
<!--                  <div class="card-header">-->
<!--                    <h4 class="card-title">Pedido</h4>-->
<!--                  </div>-->
<!--                  <div class="card-body">-->
<!--                    <div class="row">-->
<!--                      <div class="col-12">-->
<!--                        <table class="table-carrinho">-->
<!--                          <thead>-->
<!--                          <tr>-->
<!--                            <td colspan="2">Produto</td>-->
<!--                            <td>Quantidade</td>-->
<!--                            <td>Subtotal</td>-->
<!--                            <td></td>-->
<!--                          </tr>-->
<!--                          </thead>-->
<!--                          <tbody>-->
<!--                          <tr *ngFor="let pedidoProduto of (carrinho.values.produtos || []); let indexProduto = index">-->
<!--                            <td>-->
<!--                              <img-->
<!--                                [src]="pedidoProduto.produto?.imageUrl || '/assets/img/default-image.jpg'"-->
<!--                                class="img-fluid"-->
<!--                                alt="..."-->
<!--                                style="max-width: 70px"-->
<!--                              >-->
<!--                            </td>-->
<!--                            <td>-->
<!--                              {{pedidoProduto.produto?.nome || '&#45;&#45;'}}-->
<!--                            </td>-->
<!--                            <td>-->
<!--                              <div class="input-group mb-3">-->
<!--                                <span class="input-group-text">-</span>-->
<!--                                <input type="number" class="form-control" [(ngModel)]="pedidoProduto.quantidade">-->
<!--                                <span class="input-group-text">+</span>-->
<!--                              </div>-->
<!--                            </td>-->
<!--                            <td>R$ {{pedidoProduto.valor}}</td>-->
<!--                            <td>-->
<!--                              <button class="btn btn-sm btn-danger m-1">-->
<!--                                X-->
<!--                              </button>-->
<!--                            </td>-->
<!--                          </tr>-->
<!--                          </tbody>-->
<!--                        </table>-->
<!--                        <hr class="mb-5 mt-5">-->
<!--                        <ul class="list-group">-->
<!--                          <li-->
<!--                            *ngFor="let itemProduto of (carrinho.values.produtos || []); let indexProduto = index"-->
<!--                            class="list-group-item"-->
<!--                            style="padding: 0; border: none"-->
<!--                          >-->
<!--                            <food1-produtos-carrinho1 [pedidoProduto]="itemProduto" [indexPedidoProduto]="indexProduto">-->
<!--                            </food1-produtos-carrinho1>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
                </div>
              </div>
              <div class="col-xl-4 col-lg-3 col-md-4">
                <div class="card default-card">
                  <div class="card-header">
                    <h4 class="card-title">Subtotal</h4>
                  </div>

                  <div class="card-body">
                    <table class="table-carrinho">
                      <tbody>
                      <tr>
                        <td>Subtotal</td>
                        <td class="total">{{carrinho.values.total}}</td>
                      </tr>
                      <tr>
                        <td>Desconto</td>
                        <td class="total">0,00</td>
                      </tr>
                      <tr>
                        <td>Total</td>
                        <td class="total">{{carrinho.values.total}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-7">
                        <label class="form-label">Cupos de desconto</label>
                        <input class="form-control">
                      </div>
                      <div class="col-5 g-0">
                        <label class="form-label opacity-0">Eplicar</label>
                        <button class="btn btn-success btn-sm">Aplicar cupom</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-step>

          <mat-step [completed]="!!carrinho?.values?.endereco">
            <ng-template matStepLabel>Endereço</ng-template>
            <div class="row justify-content-center">
              <div class="col-8">
                <div class="card default-card">
                  <div class="card-body">
                    <div class="row mb-1" *ngIf="!!carrinho?.values?.endereco">
                      <div class="col-12">
                        <card-endereco
                          [endereco]="carrinho.values.endereco"
                          [active]="true"
                        ></card-endereco>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-6">
                        <div class="card pointer" (click)="modal.open()">
                          <div class="card-body">
                            <h6 class="card-title">Novo endereço</h6>
                            <span class="card-text">Adicionar novo endereço</span>
                          </div>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="card pointer" (click)="modalSelecionarEndereco.open()">
                          <div class="card-body">
                            <h6 class="card-title">Usar outro endereço</h6>
                            <span class="card-text">Veja os endereços cadastrados</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button matStepperNext>Next</button>
              <button matStepperPrevious>back</button>
            </div>
          </mat-step>

          <mat-step [completed]="!!formaPagamentoSelecionada.value">
            <ng-template matStepLabel>Forma de pagamento</ng-template>
            <div class="row justify-content-center">
              <div class="col-8">
                <div class="card default-card">
                  <div class="card-header">
                    <h4 class="card-title">Forma de pagamento</h4>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <card-forma-pagamento
                        (clickSelect)="selectFormaPagamento($event)"
                        class="w-100 mb-3"
                        tipo-pagamento="DINHEIRO"
                        [selected]="formaPagamentoSelecionada.value == 'DINHEIRO'"
                      ></card-forma-pagamento>
                    </div>
                    <div class="row">
                      <card-forma-pagamento
                        (clickSelect)="selectFormaPagamento($event)"
                        class="w-100 mb-3"
                        tipo-pagamento="PIX"
                        [selected]="formaPagamentoSelecionada.value == 'PIX'"
                      ></card-forma-pagamento>
                    </div>
                    <div class="row">
                      <card-forma-pagamento
                        (clickSelect)="selectFormaPagamento($event)"
                        class="w-100 mb-3"
                        tipo-pagamento="CARTAO"
                        [selected]="formaPagamentoSelecionada.value == 'CARTAO'"
                      ></card-forma-pagamento>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-step>

          <mat-step>
            <ng-template matStepLabel>Revisar pedido</ng-template>
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                  <div class="card default-card">
                    <div class="card-header">
                      <h4 class="card-title">Dados do pedido</h4>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6">
                          <label>Endereço</label>
                          <card-endereco
                            [endereco]="carrinho.values.endereco"
                            [active]="true"
                            [showActions]="false"
                          ></card-endereco>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6">
                          <label>Forma de pagamento</label>
                          <card-forma-pagamento
                            class="w-100 mb-3"
                            [selected]="true"
                            [tipo-pagamento]="formaPagamentoSelecionada.value"
                          ></card-forma-pagamento>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <table class="table-carrinho">
                        <thead>
                        <tr>
                          <td colspan="2">Produto</td>
                          <td>Quantidade</td>
                          <td>Subtotal</td>
                          <td></td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let pedidoProduto of (carrinho.values.produtos || []); let indexProduto = index">
                          <td>
                            <img
                              [src]="pedidoProduto.produto?.imageUrl || '/assets/img/default-image.jpg'"
                              class="img-fluid"
                              alt="..."
                              style="max-width: 70px"
                            >
                          </td>
                          <td>
                            {{pedidoProduto.produto?.nome || '--'}}
                          </td>
                          <td>
                            <div class="input-group mb-3">
                              <span class="input-group-text">-</span>
                              <input type="number" class="form-control" [(ngModel)]="pedidoProduto.quantidade">
                              <span class="input-group-text">+</span>
                            </div>
                          </td>
                          <td>R$ {{pedidoProduto.valor}}</td>
                          <td>
                            <button class="btn btn-sm btn-danger m-1">
                              X
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div class="col-xl-4 col-lg-4 col-md-4">
                  <div class="card default-card">
                    <div class="card-header">
                      <h4 class="card-title">Total</h4>
                    </div>

                    <div class="card-body">
                      <table class="table-carrinho">
                        <tbody>
                        <tr>
                          <td>Subtotal</td>
                          <td class="total">{{carrinho.values.total}}</td>
                        </tr>
                        <tr>
                          <td>Taxa de entrega</td>
                          <td class="total">0,00</td>
                        </tr>
                        <tr>
                          <td>Desconto</td>
                          <td class="total">0,00</td>
                        </tr>
                        <tr>
                          <td>Total</td>
                          <td class="total">{{carrinho.values.total}}</td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="card-footer">
                      <div class="row">
                        <div class="col-7">
                          <label class="form-label">Cupos de desconto</label>
                          <input class="form-control">
                        </div>
                        <div class="col-5 g-0">
                          <label class="form-label opacity-0">Eplicar</label>
                          <button class="btn btn-success btn-sm">Aplicar cupom</button>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <button [disabled]="!session.expediente" (click)="finalizar()" class="btn btn-success w-100">Finalizar compra</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </mat-step>
        </mat-stepper>
      </div>
    </div>
  </div>
</layout-food1-v2>


<div class="footer" *ngIf="stepper.selectedIndex != 3">
  <div class="container">
    <div class="row">
      <div class="col-12 d-flex justify-content-center">
        <button class="btn btn-info" (click)="next()">Avançar</button>
      </div>
    </div>
  </div>
</div>

<modal-endereco #modal></modal-endereco>

<modal-selecionar-endereco #modalSelecionarEndereco (afterSave)="carrinho.values['endereco'] = $event"></modal-selecionar-endereco>

<modal-login #modalLogin (afterSave)="afterLogin()"></modal-login>
