import { Component, OnInit } from '@angular/core';
import {HttpService} from "../../services/http-service/http.service";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";

declare type empresa = {nome: string, link: string, template: string};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public listEmpresas: empresa[] = [];

  constructor(private http: HttpService) {
    if (!environment?.develop) {
      window.location.href = environment.domainPainelUrl;
    }
    this.http.get('empresa/empresas').subscribe(resp => {
      this.listEmpresas = resp.data || [];
    })
  }

  ngOnInit(): void {
  }

  redirectEmpresa(empresa: empresa) {
    window.open(window.location.origin+'/client/'+empresa.link)
  }

}
