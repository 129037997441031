<div id="layout-food1-v2">
  <div
    *ngIf="config.modoEditor"
    class="editor-sidebar"
    [class.expanded]="isSidebarExpanded"
  >
    <div class="content-editor">
      <div class="accordion" id="accordionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingHeader">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseHeader" aria-expanded="false" aria-controls="collapseHeader">
              Header
            </button>
          </h2>
          <div id="collapseHeader" class="accordion-collapse collapse" aria-labelledby="headingHeader" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="row">
                <div class="col-xl-12 col-lg-2 col-md-4 col-sm-12">
                  <label class="form-label w-100">Cor do texto</label>
                  <div class="row">
                    <div class="col-9" style="padding-right: 0">
                      <input
                        class="form-control"
                        [(ngModel)]="config.propsTemplate!.header!.fontColor"
                        (change)="config.updateVarCss('--food1-header-font-color', config.propsTemplate!.header!.fontColor)"
                      >
                    </div>
                    <div class="col-3" style="padding-left: 0">
                      <input
                        class="form-control h-100"
                        type="color"
                        [(ngModel)]="config.propsTemplate!.header!.fontColor"
                        (change)="config.updateVarCss('--food1-header-font-color', config.propsTemplate!.header!.fontColor)"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-2 col-md-4 col-sm-12">
                  <label class="form-label w-100">Background</label>
                  <div class="row">
                    <div class="col-9" style="padding-right: 0">
                      <input
                        class="form-control"
                        [(ngModel)]="config.propsTemplate!.header!.background"
                        (change)="config.updateVarCss('--food1-header-bg', config.propsTemplate!.header!.background)"
                      >
                    </div>
                    <div class="col-3" style="padding-left: 0">
                      <input
                        class="form-control h-100"
                        type="color"
                        [(ngModel)]="config.propsTemplate!.header!.background"
                        (change)="config.updateVarCss('--food1-header-bg', config.propsTemplate!.header!.background)"
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-2 col-md-4 col-sm-12">
                  <label class="form-label w-100">Padding</label>
                  <select [(ngModel)]="config.propsTemplate!.header['padding']" class="form-select">
                    <option value="SM">Pequeno</option>
                    <option value="MD">Médio</option>
                    <option value="LG">Grande</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12 col-lg-2 col-md-4 col-sm-12">
                  <label class="form-label w-100">Cor do texto ativo</label>
                  <div class="row">
                    <div class="col-9" style="padding-right: 0">
                      <input
                        class="form-control"
                        [(ngModel)]="config.propsTemplate!.header!.fontColorActive"
                        (change)="config.updateVarCss('--food1-header-font-color-active', config.propsTemplate!.header!.fontColorActive)"
                      >
                    </div>
                    <div class="col-3" style="padding-left: 0">
                      <input
                        class="form-control h-100" type="color"
                        [(ngModel)]="config.propsTemplate!.header!.fontColorActive"
                        (change)="config.updateVarCss('--food1-header-font-color-active', config.propsTemplate!.header!.fontColorActive)"
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingBanner">
            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBanner" aria-expanded="false" aria-controls="collapseBanner">
              Banner
            </button>
          </h2>
          <div id="collapseBanner" class="accordion-collapse collapse" aria-labelledby="headingBanner" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <div class="row">
                <input
                  #inputFile hidden type="file" [(ngModel)]="file" (change)="uploadFile($event)"
                  accept="image/*"
                >
                <div class="col-12">
                  <label class="form-label w-100">Alterar imagem</label>
                  <button (click)="inputFile.click()" class="btn btn-sm btn-success">Alterar imagem</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ng-content select=[editor-content]></ng-content>

      <div class="editor-footer">
        <button class="btn btn-success w-100" (click)="salvarPropsTemplate()">Salvar alterações</button>
      </div>
    </div>
    <div class="content-button-editor">
      <button (click)="toggleSidebar()">Abrir Editor</button>
    </div>
  </div>

  <div
    *ngIf="config.modoEditor"
    class="editor-sidebar-padding"
    [class.expanded]="isSidebarExpanded"
  >.</div>

  <div id="root-layout">
    <nav
      id="public-header"
      [ngClass]="config.propsTemplate!.header['padding']"
      class="navbar navbar-expand-lg hover-editor-mode"
    >
      <div class="container-fluid">
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a class="nav-link active" aria-current="page">Nome usuário</a>
          </li>
        </ul>
        <div *ngIf="session?.user?.id" class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav ms-auto">
            <li *ngIf="session.checkPerfil('ESTABELECIMENTO')" class="nav-item">
<!--              <span class="nav-link">Habilitar Edição</span>-->
              <div class="form-check form-switch nav-link">
                <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" [(ngModel)]="editorMode" (change)="config.changeModoEditor()">
                <label class="form-check-label bold" for="flexSwitchCheckDefault">{{!config.modoEditor ? 'Habilitar' : 'Desabilitar'}} Edição</label>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="redirectRouter('historico')">Histórico</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="redirectRouter('meus-dados')">Meus dados</a>
            </li>
            <li class="nav-item">
              <span class="nav-link" (click)="session.logout()">Sair</span>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="layout-banner" class="hover-editor-mode">
      <div class="banner">
        <img
          [src]="config.getImgByPropsKey('bannerLayout')"
          srcset="assets/img/default-image.jpg"
          class="img-fluid"
          alt="..."
        >
      </div>
    </div>

    <div id="container-card-empresa" class="container-fluid hover-editor-mode">
      <div class="row justify-content-center">
        <div class="col-xl-8 col-lg-9 col-md-10 col-sm-10 col-11">
          <div class="card card-empresa default-card">
            <div class="row g-0">
              <div class="content-image col-xl-2 col-lg-2 col-md-4 col-sm-4 col-4">
                <img
                  [src]="logoEmpresa"
                  srcset="assets/img/default-image.jpg"
                  class="img-fluid rounded"
                  alt="..."
                >
              </div>
              <div class="d-flex flex-column col-xl-10 col-lg-10 col-md-8 col-sm-8 col-8">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12">
                      <h3 class="card-title">{{session.rootEmpresa?.nome || '--'}}</h3>
                      <p class="card-text text-descricao-empresa" style="text-align: justify">
                        {{getDescricaoEmpresa()}}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div *ngIf="getWidth < 768" class="col-12 d-inline-flex justify-content-center">
                      <span *ngIf="!showFullDescricaoEmpresa" (click)="showFullDescricaoEmpresa = !showFullDescricaoEmpresa" class="material-symbols-outlined">stat_minus_1</span>
                      <span *ngIf="showFullDescricaoEmpresa" (click)="showFullDescricaoEmpresa = !showFullDescricaoEmpresa" class="material-symbols-outlined">keyboard_arrow_up</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="d-inline-flex col-xl-6 col-lg-6 col-md-12 col-sm-12">
                  <span
                    class="m-1 f-bold pointer"
                    [style.color]="!!session.expediente ? 'green' : 'red'"
                  >Aberto</span>
                  <span class="m-1 ms-3 pointer"(click)="modalEmpresa.open()" style="font-weight: 500">Informações</span>
<!--                  <span class="m-1 ms-2 pointer" style="font-weight: 500">Avaliações</span>-->
                </div>
              </div>
<!--              <div class="row">-->
<!--                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">-->
<!--                  <ul class="nav">-->
<!--                    <li class="nav-item">-->
<!--                          <span-->
<!--                            class="nav-link f-bold"-->
<!--                            [style.color]="!!session.expediente ? 'green' : 'red'"-->
<!--                          >{{!!session.expediente ? 'Aberto' : 'Fechado'}}</span>-->
<!--                    </li>-->
<!--                    <li class="nav-item">-->
<!--                      <span class="nav-link pointer" (click)="modalEmpresa.open()">Informações</span>-->
<!--                    </li>-->
<!--                    <li class="nav-item">-->
<!--                      <span class="nav-link pointer" (click)="modalEmpresa.open()">Avaliações</span>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->
<!--                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">-->
<!--                  &lt;!&ndash;                      icons redes sociais&ndash;&gt;-->
<!--                </div>-->
<!--              </div>-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="content-layout2">
      <div id="content-router">
        <div class="mb-3" *ngIf="showMenu">
          <ul class="nav nav-tabs">
            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="session.baseRouter+'categotias'"
              >Todas as categorias</a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                [routerLink]="session.baseRouter"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >todos os produtos</a>
            </li>
            <li class="nav-item" *ngFor="let item of listCategorias">
              <a
                class="nav-link"
                [routerLink]="session.baseRouter"
                [queryParams]="{ categoria: item.id }"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >{{item.nome}}</a>
            </li>
          </ul>
        </div>

        <ng-content></ng-content>
      </div>
      <div *ngIf="showCarrinho" id="content-carrinho">
        <card-side-carrinho></card-side-carrinho>
      </div>
      <div *ngIf="showCarrinho" id="content-carrinho-drawer">
        <div id="sm-button-carrinho">
          <button
            class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"
          >Carrinho</button>
        </div>

        <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasBottomLabel">Carrinho</h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body small">
            <card-side-carrinho></card-side-carrinho>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<dg-modal
  #modalEmpresa
  title="Informações do estabelecimento"
  class="default-modal modal-informacoes-empresa"
  size="md"
  [bgClose]="true"
>
  <div modal-body>
    <div class="row">
      <div class="col-12">
        <h5 class="card-title">Endereço</h5>
        <p class="p-0 m-0 mt-1">
          {{session.rootEmpresa?.logradouro}}, N° {{session.rootEmpresa?.numero}} - {{session.rootEmpresa?.bairro}} -
          {{session.rootEmpresa?.cidade}} - {{session.rootEmpresa?.estado}}
        </p>
      </div>
    </div>

    <hr>

    <div class="row mb-3">
      <div class="col-12">
        <h5 class="card-title">Tempo de espera</h5>
        <p class="p-0 m-0 mt-1">Tempo de médio do delivery: <span class="f-bold">40 - 60 minutos</span></p>
        <p class="p-0 m-0">Tempo de médio do balcão: <span class="f-bold">25 - 45 minutos</span></p>
      </div>
    </div>

    <hr>

    <div class="row">
      <div class="col-12">
        <h5 class="card-title">Horários de funcionamento</h5>
        <table class="tabela-informacoes-empresa">
          <thead>
          <tr>
            <th>Dia</th>
            <th>Abertura</th>
            <th>Fechamento</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Domingo</td>
            <td *ngIf="!session?.rootEmpresa?.domingoAbertura && !session?.rootEmpresa?.domingoFechamento" colspan="2"
            >Fechado</td>
            <td *ngIf="!!session?.rootEmpresa?.domingoAbertura && !!session?.rootEmpresa?.domingoFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.domingoAbertura)}}
            </td>
            <td *ngIf="!!session?.rootEmpresa?.domingoAbertura && !!session?.rootEmpresa?.domingoFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.domingoFechamento)}}
            </td>
          </tr>
          <tr>
            <td>Segunda</td>
            <td *ngIf="!session?.rootEmpresa?.segundaAbertura && !session?.rootEmpresa?.segundaFechamento" colspan="2"
            >Fechado</td>
            <td *ngIf="!!session?.rootEmpresa?.segundaAbertura && !!session?.rootEmpresa?.segundaFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.segundaAbertura)}}
            </td>
            <td *ngIf="!!session?.rootEmpresa?.segundaAbertura && !!session?.rootEmpresa?.segundaFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.segundaFechamento || '')}}
            </td>
          </tr>
          <tr>
            <td>Terça</td>
            <td *ngIf="!session?.rootEmpresa?.tercaAbertura && !session?.rootEmpresa?.tercaFechamento" colspan="2"
            >Fechado</td>
            <td *ngIf="!!session?.rootEmpresa?.tercaAbertura && !!session?.rootEmpresa?.tercaFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.tercaAbertura)}}
            </td>
            <td *ngIf="!!session?.rootEmpresa?.tercaAbertura && !!session?.rootEmpresa?.tercaFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.tercaFechamento)}}
            </td>
          </tr>
          <tr>
            <td>Quarta</td>
            <td *ngIf="!session?.rootEmpresa?.quartaAbertura && !session?.rootEmpresa?.quartaFechamento" colspan="2"
            >Fechado</td>
            <td *ngIf="!!session?.rootEmpresa?.quartaAbertura && !!session?.rootEmpresa?.quartaFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.quartaAbertura)}}
            </td>
            <td *ngIf="!!session?.rootEmpresa?.quartaAbertura && !!session?.rootEmpresa?.quartaFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.quartaFechamento)}}
            </td>
          </tr>
          <tr>
            <td>Quinta</td>
            <td *ngIf="!session?.rootEmpresa?.quintaAbertura && !session?.rootEmpresa?.quintaFechamento" colspan="2"
            >Fechado</td>
            <td *ngIf="!!session?.rootEmpresa?.quintaAbertura && !!session?.rootEmpresa?.quintaFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.quintaAbertura)}}
            </td>
            <td *ngIf="!!session?.rootEmpresa?.quintaAbertura && !!session?.rootEmpresa?.quintaFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.quintaFechamento)}}
            </td>
          </tr>
          <tr>
            <td>Sexta</td>
            <td *ngIf="!session?.rootEmpresa?.sextaAbertura && !session?.rootEmpresa?.sextaFechamento" colspan="2"
            >Fechado</td>
            <td *ngIf="!!session?.rootEmpresa?.sextaAbertura && !!session?.rootEmpresa?.sextaFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.sextaAbertura)}}
            </td>
            <td *ngIf="!!session?.rootEmpresa?.sextaAbertura && !!session?.rootEmpresa?.sextaFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.sextaFechamento)}}
            </td>
          </tr>
          <tr>
            <td>Sábado</td>
            <td *ngIf="!session?.rootEmpresa?.sabadoAbertura && !session?.rootEmpresa?.sabadoFechamento" colspan="2"
            >Fechado</td>
            <td *ngIf="!!session?.rootEmpresa?.sabadoAbertura && !!session?.rootEmpresa?.sabadoFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.sabadoAbertura)}}
            </td>
            <td *ngIf="!!session?.rootEmpresa?.sabadoAbertura && !!session?.rootEmpresa?.sabadoFechamento"
            >
              {{pipeHorarioEmpresa(session?.rootEmpresa?.sabadoFechamento)}}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <hr>

    <div class="row mt-3">
      <div class="col-12 mb-2">
        <h5 class="card-title">Formas de pagamento</h5>
      </div>
      <div class="col-12">
        <div class="row" *ngIf="session.rootEmpresa?.formasPagamento?.includes('DINHEIRO')">
          <card-forma-pagamento
            class="w-100 mb-3"
            tipo-pagamento="DINHEIRO"
          ></card-forma-pagamento>
        </div>
        <div class="row" *ngIf="session.rootEmpresa?.formasPagamento?.includes('PIX')">
          <card-forma-pagamento
            class="w-100 mb-3"
            tipo-pagamento="PIX"
          ></card-forma-pagamento>
        </div>
        <div class="row" *ngIf="session.rootEmpresa?.formasPagamento?.includes('CARTAO')">
          <card-forma-pagamento
            class="w-100 mb-3"
            tipo-pagamento="CARTAO"
          ></card-forma-pagamento>
        </div>
      </div>
    </div>
  </div>
  <div modal-footer class="d-inline-flex justify-content-center">
    <button (click)="modalEmpresa.close()" class="btn btn-secondary">Fechar</button>
  </div>
</dg-modal>
