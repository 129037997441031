
<div class="content-editor-mode">
  <button (click)="toggleSidebar()" class="btn btn-success">ppppppppppppppppppp</button>
</div>
<!-- Sidebar -->
<div class="sidebar" [ngClass]="{'active': isSidebarVisible}">
  <h2>Configurações</h2>
  <ng-content></ng-content>
  <button class="btn btn-secondary" (click)="toggleSidebar()">Fechar</button>
</div>
