import {Injectable} from '@angular/core';
import {HttpService} from "../http-service/http.service";
import {rootEmpresa} from "../session-service/session.service";
import {
  TEMPLATE_ROUTERS,
} from "../../core/config/template-routers/template-routers";
import {replaceEndpointParams} from "../../helpers/endpoints-helpers";
import {ROOT_ESTABELECIMENTO} from "../../core/config/endpoints";
import {Route} from "@angular/router";

/**
 * classe de inicialização do sistemna de layouts.
 */
@Injectable({
  providedIn: 'root'
})
export class InitLayoutService {

  // dados basicos da empresa
  public rootEmpresa: rootEmpresa | undefined;

  constructor(private service: HttpService) {
  }

  /**
   * Metodo de inicialização.
   * @param empresaNome
   */
  findTemplate(empresaNome: string, callback: Function) {
    if (!this.issetRootEmpresa()) {
      this.service.get(replaceEndpointParams(ROOT_ESTABELECIMENTO, empresaNome)).subscribe(resp => {
        if (!resp.data) return;
        resp.data['formasPagamento'] = (resp.data['formasPagamento'] || '').split(',')
        this.saveEmpresaLayout(resp.data)
        callback()
      })
    }
  }

  /**
   * metodo para verificação de existencia dos dados da empres
   * caso existam, ele irá setar automaticamente na propriedade "rootEmpresa"
   */
  issetRootEmpresa(): boolean {
    if (!!this.rootEmpresa) {
      return true;
    }
    const rootEmpresa = window.sessionStorage.getItem('rootEmpresa');

    if (!!rootEmpresa) {
      this.rootEmpresa = JSON.parse(rootEmpresa);
      return true;
    }

    return false;
  }

  /**
   * Metodo para buscar empresa com base no nome dela
   * @param empresaNome
   */
  async getEmpresa(empresaNome: string) {
    await this.service.get(replaceEndpointParams(ROOT_ESTABELECIMENTO, empresaNome)).subscribe(resp => {
      if (!resp.data) return;
      resp.data['formasPagamento'] = (resp.data['formasPagamento'] || '').split(',')
      this.saveEmpresaLayout(resp.data)
    })
  }

  /**
   * metodo para salvar dados da empresa
   * @param empresa
   */
  saveEmpresaLayout(empresa: rootEmpresa) {
    window.sessionStorage.setItem('rootEmpresa', JSON.stringify(empresa))
  }

  /**
   * Metodo retorna rotas do layout escolhido.
   */
  verifySetRouters(): Route | undefined {
    if (this.issetRootEmpresa()) {
      if (!this.rootEmpresa) return;
      if (this.rootEmpresa.template in TEMPLATE_ROUTERS) {
        return TEMPLATE_ROUTERS[this.rootEmpresa.template]
      }
    }
    return undefined;
  }
}
