
export type ImageTemplateEntity = {
  "id": string,
  "propsKey": string,
  "imageUrl": string
}

export abstract class AbstractTemplateEntity {
  constructor(
    id?: string,
    nome?: string,
    mapImg?: ImageTemplateEntity[],
  ) {
    this.id = id || '';
    this.nome = nome || '';
    this.mapImg = mapImg || [];
  }

  id: string;
  nome: string;
  mapImg: ImageTemplateEntity[];

  abstract  getListUpdateVariablesCss(): ([string, string][]);
}
