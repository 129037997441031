import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {SessionService} from "../../services/session-service/session.service";
import {InitLayoutService} from "../../services/init-layout-service/init-layout.service";
import {replaceEndpointParams} from "../../helpers/endpoints-helpers";
import {environment} from "../../../environments/environment";
import {TokenService} from "../../services/token-service/token.service";
import {CarrinhoService} from "../../services/carrinho-service/carrinho.service";
import {ConfigDesignLayoutService} from "../../services/config-design-layout-service/config-design-layout.service";

@Injectable({
  providedIn: 'root'
})
export class LayoutGuard implements CanActivate {

  constructor(
    private templateService: InitLayoutService,
    private session: SessionService,
    private token: TokenService,
    private carrinho: CarrinhoService,
    private configDesign: ConfigDesignLayoutService
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    if (!this.templateService.issetRootEmpresa()) {
      //TODO pensar o que fazer nesse caso "LayoutGuard"
      alert('pensar o que fazer nesse caso "LayoutGuard"')
    }

    if (this.templateService.rootEmpresa?.link != route.params['nomeEmpresa']) {
      window.location.reload();
    }
    if (!this.session.rootEmpresa) {
      this.session.rootEmpresa = this.templateService.rootEmpresa;
    }

    if (this.token.issetToken()) {
      if (!this.session.user.id) {
        this.session.setUser();
      }
    }

    if (!this.configDesign.issetPropsLayot()) {
      console.log('verificar uma forma melhor de lidar com esse erro')
      return false
    }

    this.carrinho.issetValues();

    this.session.baseRouter = replaceEndpointParams(environment.baseRouter, String(this.session.rootEmpresa?.link))

    return true;
  }

}
