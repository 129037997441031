<layout-food1-v2 [showCarrinho]="false" [showMenu]="false">
  <div id="acompanhar-pedido" class="container mt-4 mb-5 pb-5">
    <div class="row">
      <div class="col-12">
        <ul class="list-group list-group-horizontal">
          <li class="list-group-item" [routerLink]="session.baseRouter">Fazer um novo pedido</li>
          <li class="list-group-item" [routerLink]="session.baseRouter+'historico'">Histórico de pedidos</li>
        </ul>
      </div>
    </div>

    <div class="row mt-3 justify-content-center">
      <div class="col-xl-3 col-lg-3 col-md-4 col-sm-10 col-10">
        <div class="card default-card">
          <div class="card-header">
            Acompanhar meu pedido
          </div>
          <div class="card-body">
            <p class="status" [class.active]="venda.status == 'EM_ANALISE'">Em analise</p>
            <p class="status" [class.active]="venda.status == 'PRODUCAO'">Em preparação</p>
            <p class="status" [class.active]="venda.status == 'CAMINHO'">A caminho</p>
            <p class="status" [class.active]="venda.status == 'ENTREGUE'">Entregue</p>
<!--            <ul class="list-group">-->
<!--              <li class="list-group-item" [class.disabled]="venda.status != 'EM_ANALISE'">Em analise</li>-->
<!--              <li class="list-group-item" [class.disabled]="venda.status != 'PRODUCAO'">Em preparação</li>-->
<!--              <li class="list-group-item" [class.disabled]="venda.status != 'CAMINHO'">A caminho</li>-->
<!--              <li class="list-group-item" [class.disabled]="venda.status != 'ENTREGUE'">Entregue</li>-->
<!--            </ul>-->
          </div>
        </div>
      </div>
      <div class="col-xl-9 col-lg-3 col-md-8 col-sm-10 col-10">
        <div class="card default-card card-pedido">
          <div class="card-header">
            <h4 class="card-title">Pedido - status: {{venda.status}}</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <label>Endereço</label>
                <card-endereco
                  [endereco]="venda?.endereco"
                  [active]="true"
                  [showActions]="false"
                ></card-endereco>
              </div>
              <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                <label>Forma de pagamento</label>
<!--                <card-forma-pagamento-->
<!--                  class="w-100 mt-auto mb-auto"-->
<!--                  [selected]="true"-->
<!--                  [tipo-pagamento]="formaPagamentoSelecionada.value"-->
<!--                ></card-forma-pagamento>-->
              </div>
            </div>
          </div>
          <div class="card-body">

            <div class="row mb-2 justify-content-center">
              <div class="col-11">
                <table class="dg-table table-carrinho w-100">
                  <thead>
                  <tr>
                    <td colspan="2">Produto</td>
                    <td>Quantidade</td>
                    <td>Subtotal</td>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let pedidoProduto of (venda.produtos || []); let indexProduto = index">
                    <td>
                      <img
                        [src]="pedidoProduto.produto?.imageUrl || '/assets/img/default-image.jpg'"
                        srcset="assets/img/default-image.jpg"
                        class="img-fluid"
                        alt="..."
                        style="max-width: 70px"
                      >
                    </td>
                    <td>
                      {{pedidoProduto?.nome || '--'}}

                      <ul class="list-group list-complementos">
                        <li *ngFor="let itemComplemento of pedidoProduto.complementosPedido" class="list-group-item">
                          <ul class="list-group list-complementos">
                            <li *ngFor="let itemPedido of itemComplemento.itemsPedido" class="list-group-item">
                              {{itemPedido?.nome}}
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </td>
                    <td>

                    </td>
                    <td>R$ {{pedidoProduto.valor}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>


<!--            <div class="row">-->
<!--              <div class="col-12">-->
<!--                <div class="accordion">-->
<!--                  <div class="accordion-item" *ngFor="let itemProduto of (venda.produtos || []); let indexProduto = index">-->
<!--                    <h2 class="accordion-header" [attr.id]="'id-complementos-button-'+ indexProduto">-->
<!--                      <button-->
<!--                        class="accordion-button"-->
<!--                        type="button"-->
<!--                        data-bs-toggle="collapse"-->
<!--                        [attr.data-bs-target]="'#id-complementos-content-'+ indexProduto"-->
<!--                        aria-expanded="true"-->
<!--                        [attr.aria-controls]="'id-complementos-content-'+ indexProduto"-->
<!--                      >-->
<!--                        {{itemProduto.nome}}-->
<!--                      </button>-->
<!--                    </h2>-->
<!--                    <div [attr.id]="'id-complementos-content-'+ indexProduto" class="accordion-collapse collapse show" [attr.aria-labelledby]="'id-complementos-button-'+ indexProduto">-->
<!--                      <div class="accordion-body">-->
<!--                        <div class="row">-->
<!--                          <div class="col-12">-->
<!--                            {{itemProduto.nome}}-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div class="row">-->
<!--                          <div class="col-12">-->
<!--                            <ul *ngIf="itemProduto.complementosPedido.length > 0" class="list-group">-->
<!--                              <li *ngFor="let itemComplemento of (itemProduto.complementosPedido || [])" class="list-group-item">-->
<!--                                <div class="row">-->
<!--                                  <div class="col-12">-->
<!--                                    {{itemComplemento.nome}}-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                                <div class="row">-->
<!--                                  <div class="col-12">-->
<!--                                    <ul *ngIf="itemComplemento.itemsPedido.length > 0" class="list-group">-->
<!--                                      <li *ngFor="let item of (itemComplemento?.itemsPedido || [])" class="list-group-item">-->
<!--                                        {{item?.nome}}-->
<!--                                      </li>-->
<!--                                    </ul>-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </li>-->
<!--                            </ul>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  <pre>{{venda | json}}</pre>-->
</layout-food1-v2>

