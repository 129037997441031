import {Component, Input, OnInit} from '@angular/core';
import {HttpService} from "../../../../services/http-service/http.service";
import {CATEGORIA, TEMPLATE, TEMPLATE_IMG} from "../../../../core/config/endpoints";
import {SessionService} from "../../../../services/session-service/session.service";
import {Router} from "@angular/router";
import {
  ConfigDesignLayoutService
} from "../../../../services/config-design-layout-service/config-design-layout.service";
import {TemplateFood1PropsEntity} from "../../../../entitys/template-entitys/template-food1.props.entity";
import {replaceEndpointParams} from "../../../../helpers/endpoints-helpers";
import {AbstractTemplateEntity} from "../../../../entitys/template-entitys/abstract-template.entity";

@Component({
  selector: 'layout-food1',
  templateUrl: './layout-food1.component.html',
  styleUrls: ['./layout-food1.component.scss']
})
export class LayoutFood1Component implements OnInit {

  listCategorias: any[] = [];
  @Input() showCarrinho: boolean = true;
  @Input() showMenu: boolean = true;
  file: File | undefined;

  constructor(
    private http: HttpService,
    public session: SessionService,
    private router: Router,
    public config: ConfigDesignLayoutService<TemplateFood1PropsEntity>
  ) {
    http.get(CATEGORIA).subscribe(resp => {
      this.listCategorias = resp.data;
    })
    http.get('empresa/expediente').subscribe(resp => {
      this.session.setExpediente(resp.data)
    })
  }

  ngOnInit(): void {
  }

  carrinho() {
    this.router.navigate([this.session.baseRouter, 'carrinho']).then()
  }

  salvarPropsTemplate() {
    alert('resolva o template');
    debugger
    // const props = JSON.parse(JSON.stringify(this.config.propsTemplate))
    // delete props['id'];
    // delete props['nome'];
    // delete props['props'];
    // // @ts-ignore
    // this.config.propsTemplate['props'] = JSON.stringify(props);
    //
    // const data = {
    //   id: this.config.propsTemplate?.id,
    //   nome: this.config.propsTemplate?.nome,
    //   props: this.config.propsTemplate?.props,
    // }
    //
    // this.http.put(TEMPLATE, data).subscribe(resp => {
    //   this.config.atualizarSessionStoragy()
    // })
  }

  uploadFile(event?: Event) {
    //@ts-ignore
    if (!event.target.files) {
      return;
    }
    //@ts-ignore
    const file: File = event.target.files[0];

    if (!file) return;

    const data = new FormData();
    data.set('file', file);

    const params = [this.config.propsTemplate?.id || '', 'bannerLayout']

    this.http.post(replaceEndpointParams(TEMPLATE_IMG, params), data).subscribe(resp => {
      this.config?.updateImgByPropsKey(resp)
      this.config.atualizarSessionStoragy();
    })
  }

  updateVarCss(varName: string, value: string) {
    console.log(varName, value)
    document.documentElement.style.setProperty(varName, value);
  }
}
