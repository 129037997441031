import { Component, OnInit } from '@angular/core';
import {SessionService} from "../../../../services/session-service/session.service";
import {Router} from "@angular/router";
import {CarrinhoService} from "../../../../services/carrinho-service/carrinho.service";

@Component({
  selector: 'card-side-carrinho',
  templateUrl: './card-side-carrinho.component.html',
  styleUrls: ['./card-side-carrinho.component.scss', '../../scss/index.scss']
})
export class CardSideCarrinhoComponent implements OnInit {

  constructor(
    public session: SessionService,
    private router: Router,
    public carrinho: CarrinhoService
  ) { }

  ngOnInit(): void {
  }

  link_carrinho() {
    this.router.navigate([this.session.baseRouter, 'carrinho']).then()
  }

}
