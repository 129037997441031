<div class="card card-endereco">
  <div class="row g-0">
    <div class="col-2 col-icon">
      <span class="material-symbols-outlined fs-30" [class.active]="active">person_pin_circle</span>
    </div>
    <div class="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-8 col-body">
      <div class="card-body">
        <h6 class="card-title">
          {{endereco?.rua}} {{endereco?.numero}} {{!!endereco?.complemento ? ' ,'+endereco?.complemento: ''}}
        </h6>
        <p class="card-text m-0 p-0">{{endereco?.cidade}} | {{endereco?.bairro}}</p>
      </div>
    </div>
    <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 col-2 col-actions" *ngIf="showActions">
      <span (click)="clickEdit.emit(endereco)" class="material-symbols-outlined edit">edit</span>
      <span (click)="delete()" class="material-symbols-outlined delete">delete</span>
    </div>
  </div>
</div>
