import {Component, OnInit, ViewChild} from '@angular/core';
import {SessionService} from "../../../../services/session-service/session.service";
import {HttpService} from "../../../../services/http-service/http.service";
import {Router} from "@angular/router";
import {CarrinhoService} from "../../../../services/carrinho-service/carrinho.service";
import {ModalLoginComponent} from "../../../../shared/modal/modal-login/modal-login.component";
import {MatStepper} from "@angular/material/stepper/stepper";
import {ClienteEnderecoEntity} from "../../../../entitys/cliente-endereco.entity";
import {FormControl, Validators} from "@angular/forms";
import {CARRINHO, ENDERECO_ULTIMO_USADO, VENDAS_ORCAMENTO} from "../../../../core/config/endpoints";
import {VendaEntity} from "../../../../entitys/venda.entity";
import Swal from "sweetalert2";

@Component({
  selector: 'app-food1-carrinho',
  templateUrl: './food1-carrinho.component.html',
  styleUrls: ['./food1-carrinho.component.scss', '../../scss/index.scss']
})
export class Food1CarrinhoComponent implements OnInit {

  @ViewChild('stepper') stepper!: MatStepper;
  @ViewChild('modalLogin') modalLogin!: ModalLoginComponent;

  enderecos: ClienteEnderecoEntity[] = [];
  formaPagamentoSelecionada = new FormControl('DINHEIRO', [Validators.required]);
  listFormasPagamento: string[] = [];

  constructor(
    public carrinho: CarrinhoService,
    public session: SessionService,
    private http: HttpService,
    private router: Router
  ) {
    this.listFormasPagamento = session.rootEmpresa?.formasPagamento || []

    if (this.session.user?.id) {
      this.getUltimoEndereco()
    }
  }

  ngOnInit(): void {
  }

  finalizar() {
    if (!this.session.user.id) {
      this.router.navigate([this.session.baseRouter, 'login']).then();
      return;
    }
    if (this.carrinho.values.produtos.length <= 0) {
      alert('não é possível realizar uma compra sem produtos')
      return;
    }
    if (this.formaPagamentoSelecionada.invalid) {
      alert('Selecione uma forma de pagamento')
      return;
    }

    const data = {
      ...this.carrinho.values,
      cliente: {id:this.session.user.id},
      formaPagamento: this.formaPagamentoSelecionada.value
    }

    this.http.post(CARRINHO, data).subscribe(resp => {
      this.carrinho.values = new VendaEntity();
      this.router.navigate([this.session.baseRouter, 'acompanhar-pedido', resp.data.id]).then()
      Swal.fire({
        icon: 'success',
        title: 'Pedido realizado'
      }).then()
    })
  }

  next() {
    if (this.stepper.selectedIndex == 0) {
      if (!this.session.user.id) {
        this.modalLogin.open()
        return;
      }
    } else if (this.stepper.selectedIndex == 2) {
      if (!this.verifystepperValidate(2)) {
        this.getOrcamento();
        return;
      }
    }
    this.stepper.next()
  }

  getOrcamento() {
    if (this.carrinho.values.produtos.length <= 0) {
      alert('não é possível realizar uma compra sem produtos')
      return;
    }
    if (this.formaPagamentoSelecionada.invalid) {
      alert('Selecione uma forma de pagamento')
      return;
    }

    const data = {
      ...this.carrinho.values,
      cliente: {id:this.session.user.id},
      formaPagamento: this.formaPagamentoSelecionada.value
    }

    this.http.post(VENDAS_ORCAMENTO, data).subscribe(resp => {
      this.carrinho.values.total = resp.data.total;
      this.carrinho.values.subtotal = resp.data.subtotal;
      this.carrinho.values.taxaEntrega = resp.data.taxaEntrega;
      setTimeout(() => this.next(), 10)
    })
  }

  verifystepperValidate(index: number) {
    if (index == 0) {
      return !!this.session.user.id;
    } else if (index == 1) {
      return !!this.carrinho?.values?.endereco;
    } if (index == 2) {
      const carrinho = this.carrinho.values;
      return !!this.formaPagamentoSelecionada.value && !!carrinho.subtotal;
    }

    return true;
  }

  afterLogin() {
    this.getUltimoEndereco()

    if (this.stepper.selectedIndex == 0) {
      setTimeout(() => this.stepper.next(), 10)
    }
  }

  getUltimoEndereco() {
    this.http.get(ENDERECO_ULTIMO_USADO).subscribe(resp => {
      if (!!resp?.data) {
        this.carrinho.values.endereco = resp.data
      }
    })
  }

  selectFormaPagamento(formaPgt: any) {
    this.formaPagamentoSelecionada.patchValue(formaPgt)
  }
}
