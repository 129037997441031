import {ProdutoComplementoItemEntity} from "./produto-complemento-item.entity";


export class PedidoProdutoComplementoItemEntity {
  constructor(
    id?: string,
    nome?: string,
    selected?: boolean,
    quantidade?: number,
    item?: ProdutoComplementoItemEntity,
  ) {
    this.id = id
    this.nome = nome
    this.selected = !!selected
    this.item = item
    this.quantidade = quantidade || 0
  }

  nome: string | undefined
  id: string | undefined
  selected: boolean | undefined
  item: ProdutoComplementoItemEntity | undefined
  quantidade: number = 0;
}
