import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {SessionService} from "../../services/session-service/session.service";
import {HttpService} from "../../services/http-service/http.service";
import {TEMPLATE_VERIFY_TOKEN} from "../../core/config/endpoints";
import Swal from "sweetalert2";

@Component({
  selector: 'app-start-editor-mode',
  templateUrl: './start-editor-mode.component.html',
  styleUrls: ['./start-editor-mode.component.scss']
})
export class StartEditorModeComponent implements OnInit {

  constructor(
    private router: Router,
    private session: SessionService,
    private http: HttpService,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    const token = this.activatedRoute.snapshot.params['token'];
    if (token) {
      this.http.get(TEMPLATE_VERIFY_TOKEN, {headers: {authorization: 'Bearer ' + token}}).subscribe(
        resp => {
          if (!!resp.data) {
            this.session.token.saveToken(token)
            this.router.navigate([this.session.baseRouter]).then(() => {
              window.location.reload()
            });
          }
        },
        error => {
          Swal.fire({
            icon: 'error',
            title: 'Acesso negado'
          }).then(() => {
            window.close();
          })
        })
    }
    window.sessionStorage.setItem('modo-editor', '1');
    // this.router.navigate([this.session.baseRouter]).then();
  }

}
