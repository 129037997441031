import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClienteEnderecoEntity} from "../../../../entitys/cliente-endereco.entity";
import {flip} from "@popperjs/core";
import {HttpService} from "../../../../services/http-service/http.service";
import {ENDERECO} from "../../../../core/config/endpoints";
import Swal from "sweetalert2";

@Component({
  selector: 'card-endereco',
  templateUrl: './card-endereco.component.html',
  styleUrls: ['./card-endereco.component.scss']
})
export class CardEnderecoComponent implements OnInit {

  @Input('endereco') endereco: ClienteEnderecoEntity | undefined;
  @Input('showActions') showActions: boolean = true;
  @Input('active') active: boolean = false;

  @Output('clickEdit') clickEdit = new EventEmitter<ClienteEnderecoEntity>()
  @Output('afterDelete') afterDelete = new EventEmitter()

  constructor(private http: HttpService) { }

  ngOnInit(): void {
  }

  delete() {
    if (!this.endereco) return;

    Swal.fire({
      icon: 'question',
      title: "Excluir endereço",
      showCancelButton: true,
      cancelButtonText: 'Sair',
      confirmButtonText: "Excluir"
    }).then(conf => {
      if (conf.isConfirmed) {
        this.http.delete(ENDERECO+'/'+this.endereco!.id).subscribe(resp => {
          this.afterDelete.emit()
          Swal.fire({
            icon: 'success',
            title: "Endereço excluído",
            timer: 1000
          }).then()
        })
      }
    })
  }

}
