<div class="content-editor-mode">
  <button class="btn btn-success" (click)="toggleSidebar()">Editar</button>
</div>

<!-- Sidebar -->
<div
  class="sidebar"
  [ngClass]="{'active': isSidebarVisible}"
  [class.right]="directionMenu == 'right'"
>
  <div class="container-fluid">
    <div class="row">
      <div class="col-5">
        <button class="btn" (click)="directionMenu = 'left'"><-</button>
      </div>
      <div class="col-5">
        <button class="btn" (click)="directionMenu = 'right'">-></button>
      </div>
      <div class="col-2 d-inline-flex justify-content-end">
        <button (click)="toggleSidebar()" class="btn">
          <mat-icon class="pointer">close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <ng-content></ng-content>
  </div>
</div>
