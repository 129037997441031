import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ProdutoEntity} from "../../../../../entitys/produto.entity";
import {
  ConfigDesignLayoutService
} from "../../../../../services/config-design-layout-service/config-design-layout.service";
import {PedidoProdutoEntity} from "../../../../../entitys/pedido-produto.entity";
import {ProdutoService} from "../../../../../services/produto-service/produto.service";
import {PedidoProdutoComplementoItemEntity} from "../../../../../entitys/pedido-produto-complemento-item.entity";

@Component({
  selector: 'food1-produtos1',
  templateUrl: './food1-produtos1.component.html',
  styleUrls: ['./food1-produtos1.component.scss', '../../../scss/index.scss']
})
export class Food1Produtos1Component implements OnInit {

  @Input() pedidoProduto!: PedidoProdutoEntity;
  statusSelecao: 'complementos' | 'observacao' | 'bebidas' = 'complementos';

  @Output("finalizar") finalizar = new EventEmitter();

  configComplementos: {
    limit: number,
    totalSelected: number,
  }[] = [];

  constructor(public config: ConfigDesignLayoutService, public produtoService: ProdutoService) {
  }

  ngOnInit(): void {
    const configComplementos: {
      limit: number,
      totalSelected: number,
    }[] = [];
    const listComplementos = this.pedidoProduto.complementosPedido || [];

    listComplementos.map(item => {
      configComplementos.push({
        limit: Number(item.complemento?.limit || 0),
        totalSelected: 0
      })
    })

    this.configComplementos = configComplementos;
  }

  verifySelectedItem(ev: any, indexComplemento: number, indexItem: number) {
    const complementoPedido = this.pedidoProduto.complementosPedido[indexComplemento];
    if (!complementoPedido) return;

    if (complementoPedido.complemento?.tipoSelecao == "UNITARIO") {
      this.produtoService.verifySelectInputClicked(complementoPedido, indexItem);
      return;
    }

    const itemComplemento = complementoPedido.itemsPedido[indexItem]
    if (!itemComplemento) return;

    if (!!ev) {
      if (itemComplemento.quantidade == 0) {
        itemComplemento.quantidade = 1;
      }
    } else {
      itemComplemento.quantidade = 0;
    }
  }

  addQuantidade(indexComplemento: number, indexItem: number) {
    const complementoPedido = this.pedidoProduto.complementosPedido[indexComplemento];
    const item = complementoPedido.itemsPedido[indexItem]

    if (!!item.item?.limit) {
      if (item.quantidade >= item.item.limit) return;
    }

    if (this.configComplementos[indexComplemento].totalSelected >= this.configComplementos[indexComplemento].limit) {
      return;
    }

    if (item.quantidade == 0) {
      item.selected = true;
    }
    item.quantidade = item.quantidade + 1

    this.updateLimitSelecaoDisponivelPorComplemento(indexComplemento)
  }

  subtraiQuantidade(indexComplemento: number, indexItem: number) {
    const complementoPedido = this.pedidoProduto.complementosPedido[indexComplemento];
    const item = complementoPedido.itemsPedido[indexItem]

    if (item.quantidade <= 0) return;
    item.quantidade = item.quantidade - 1

    if (item.quantidade == 0) {
      item.selected = false;
    }

    this.updateLimitSelecaoDisponivelPorComplemento(indexComplemento);
  }

  updateLimitSelecaoDisponivelPorComplemento(indexComplemento: number) {
    let totalQuantidade = 0;
    this.pedidoProduto.complementosPedido[indexComplemento].itemsPedido
      .filter(itemP => itemP.quantidade)
      .map(itemP => totalQuantidade = totalQuantidade + itemP.quantidade);

    this.configComplementos[indexComplemento].totalSelected = totalQuantidade;
  }

  nextStatus() {
    switch (this.statusSelecao) {
      case 'complementos':
        this.statusSelecao = 'observacao';
        break;
      case 'observacao':
        break;
      default:
        return
    }
  }

  backStatus() {
    switch (this.statusSelecao) {
      case 'complementos':
        break;
      case 'observacao':
        this.statusSelecao = 'complementos';
        break;
      default:
        return
    }
  }

  verifyDisabledButtonNext(): boolean {
    if (this.statusSelecao == 'complementos') {
      if (this.pedidoProduto?.complementosPedido.length == 0) return false;

      const result = this.pedidoProduto?.complementosPedido.filter(complemento => {
        if (!!complemento?.complemento?.obrigatorio) {
          return complemento.itemsPedido.filter(item => item.selected).length == 0
        }
        return false;
      }) || []

      return result.length > 0;
    }

    return false;
  }
}
