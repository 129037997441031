<layout-food1-v2>
  <div class="container-fluid mb-5">
    <div class="row">
      <div class="col-3">
        <img
          [src]="pedidoProduto?.produto?.imageUrl || '/assets/img/default-image.jpg'"
          class="img-fluid rounded-start"
          alt="..."
        >
      </div>
      <div class="col-9">
        <h4>{{pedidoProduto?.nome || '--'}}</h4>
        <p>R$: {{pedidoProduto?.valor || '--'}}</p>
        <p class="card-text">{{pedidoProduto?.produto?.descricao}}</p>
      </div>
    </div>
  </div>

  <food1-produtos1 *ngIf="pedidoProduto" [pedidoProduto]="pedidoProduto" (finalizar)="finalizar()"></food1-produtos1>

</layout-food1-v2>
