<nav
  id="public-header"
  class="navbar {{config.propsTemplate!.header['padding']}} navbar-expand-lg hover-editor-mode"
>
  <editor-mode-hover *ngIf="config.modoEditor">
    <div class="row">
      <div class="col-xl-12 col-lg-2 col-md-4 col-sm-12">
        <label class="form-label w-100">Cor do texto</label>
        <div class="row">
          <div class="col-9" style="padding-right: 0">
            <input
              class="form-control"
              [(ngModel)]="config.propsTemplate!.header!.fontColor"
              (change)="updateVarCss('--food1-header-font-color', config.propsTemplate!.header!.fontColor)"
            >
          </div>
          <div class="col-3" style="padding-left: 0">
            <input
              class="form-control h-100"
              type="color"
              [(ngModel)]="config.propsTemplate!.header!.fontColor"
              (change)="updateVarCss('--food1-header-font-color', config.propsTemplate!.header!.fontColor)"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-2 col-md-4 col-sm-12">
        <label class="form-label w-100">Background</label>
        <div class="row">
          <div class="col-9" style="padding-right: 0">
            <input
              class="form-control"
              [(ngModel)]="config.propsTemplate!.header!.background"
              (change)="updateVarCss('--food1-header-bg', config.propsTemplate!.header!.background)"
            >
          </div>
          <div class="col-3" style="padding-left: 0">
            <input
              class="form-control h-100"
              type="color"
              [(ngModel)]="config.propsTemplate!.header!.background"
              (change)="updateVarCss('--food1-header-bg', config.propsTemplate!.header!.background)"
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-2 col-md-4 col-sm-12">
        <label class="form-label w-100">Padding</label>
        <select [(ngModel)]="config.propsTemplate!.header['padding']" class="form-select">
          <option value="SM">Pequeno</option>
          <option value="MD">Médio</option>
          <option value="LG">Grande</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-2 col-md-4 col-sm-12">
        <label class="form-label w-100">Cor do texto ativo</label>
        <div class="row">
          <div class="col-9" style="padding-right: 0">
            <input
              class="form-control"
              [(ngModel)]="config.propsTemplate!.header!.fontColorActive"
              (change)="updateVarCss('--food1-header-font-color-active', config.propsTemplate!.header!.fontColorActive)"
            >
          </div>
          <div class="col-3" style="padding-left: 0">
            <input
              class="form-control h-100" type="color"
              [(ngModel)]="config.propsTemplate!.header!.fontColorActive"
              (change)="updateVarCss('--food1-header-font-color-active', config.propsTemplate!.header!.fontColorActive)"
            >
          </div>
        </div>
      </div>
    </div>
  </editor-mode-hover>

  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav me-auto">
      <li class="nav-item">
        <a class="nav-link active" aria-current="page" [routerLink]="null">Nome usuário</a>
      </li>
    </ul>
    <div *ngIf="session.user.id" class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="null">Histórico</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="null">Meus dados</a>
        </li>
        <li class="nav-item">
          <span class="nav-link" (click)="session.logout()">Sair</span>
        </li>
      </ul>
    </div>
  </div>
</nav>

<div id="layout-banner" class="hover-editor-mode">
  <editor-mode-hover *ngIf="config.modoEditor">
    <div class="row">
      <input
        #inputFile hidden type="file" [(ngModel)]="file" (change)="uploadFile($event)"
        accept="image/*"
      >
      <div class="col-12">
        <label class="form-label w-100">Alterar imagem</label>
        <button (click)="inputFile.click()" class="btn btn-sm btn-success">Alterar imagem</button>
      </div>
    </div>
  </editor-mode-hover>
  <div class="banner">
    <img [src]="config.getImgByPropsKey('bannerLayout')" class="img-fluid" alt="...">
  </div>
</div>

<div id="container-card-empresa" class="container-fluid hover-editor-mode">
  <div class="row justify-content-center">
    <div class="col-7">
      <div class="card card-empresa">
        <div class="row g-0">
          <div class="content-image col-2">
            <img
              src="/assets/img/templates/FOOD1/logo.jpg"
              class="img-fluid rounded"
              alt="..."
            >
          </div>
          <div class="d-flex flex-column col-10">
            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <h3 class="card-title">{{session.rootEmpresa?.nome || '--'}}</h3>
                  <p class="card-text">
                    Ut nibh neque, laoreet vel pulvinar id, interdum quis massa. Donec eu arcu leo. Duis ligula quam, tempor at mauris ut, pulvinar interdum sapien. Mauris mollis ipsum ut sagittis convallis.
                  </p>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <span *ngIf="!!session.expediente" class="card-text f-bold">Aberto</span>
                  <span *ngIf="!session.expediente" class="card-text f-bold">Fechado</span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid mb-5" style="padding: 0 1em">
  <div class="row">
    <div class="{{showCarrinho ? 'col-xl-9 col-lg-8 col-md-8' : 'col-12'}} col-sm-12">
      <div *ngIf="showMenu">
        <ul class="list-group list-group-horizontal">
          <li class="list-group-item" [routerLink]="session.baseRouter+'categotias'">Todas as categorias</li>
          <li class="list-group-item" [routerLink]="session.baseRouter">todos os produtos</li>
          <li
            *ngFor="let item of listCategorias" class="list-group-item"
            [routerLink]="session.baseRouter" [queryParams]="{ categoria: item.id }"
          >{{item.nome}}</li>
        </ul>
      </div>

      <ng-content></ng-content>
    </div>
    <div *ngIf="showCarrinho" class="col-xl-3 col-lg-4 col-md-4 col-sm-12">
      <card-side-carrinho></card-side-carrinho>
    </div>
  </div>
</div>

<nav *ngIf="session.checkPerfil('ESTABELECIMENTO')" id="public-editor-mode" class="navbar navbar-expand-lg">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav-editor-mode" aria-controls="navbarNav-editor-mode" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <ul class="navbar-nav me-auto">
      <li class="nav-item">
        <a class="nav-link active" aria-current="page" [routerLink]="null">Modo editor</a>
      </li>
    </ul>
    <div *ngIf="session.user.id" class="collapse navbar-collapse" id="navbarNav-editor-mode">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item">
          <button *ngIf="!config.modoEditor" (click)="config.changeModoEditor()" class="btn btn-sm btn-success m-1">Habilitar modo editor</button>
          <button *ngIf="config.modoEditor" (click)="config.changeModoEditor()" class="btn btn-sm btn-secondary m-1">Desabilitar modo editor</button>
        </li>
        <li class="nav-item">
          <button (click)="salvarPropsTemplate()" *ngIf="config.modoEditor" class="btn btn-sm btn-success m-1">Salvar</button>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div *ngIf="showMenu" style="height: 70px">.</div>
