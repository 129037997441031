import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { SharedLoadEmpresaComponent } from './shared-load-empresa/shared-load-empresa.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { StartEditorModeComponent } from './start-editor-mode/start-editor-mode.component';



@NgModule({
  declarations: [
    HomeComponent,
    SharedLoadEmpresaComponent,
    StartEditorModeComponent
  ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ]
})
export class SharedPagesModule { }
