import { Component } from '@angular/core';
import {SessionService} from "../../services/session-service/session.service";
import {ActivatedRoute} from "@angular/router";
import {HttpService} from "../../services/http-service/http.service";
import {InitLayoutService} from "../../services/init-layout-service/init-layout.service";
import {ConfigDesignLayoutService} from "../../services/config-design-layout-service/config-design-layout.service";

@Component({
  selector: 'app-shared-load-empresa',
  templateUrl: './shared-load-empresa.component.html',
  styleUrls: ['./shared-load-empresa.component.scss']
})
export class SharedLoadEmpresaComponent {
  constructor(
    private session: SessionService,
    private route: ActivatedRoute,
    private service: HttpService,
    private templateService: InitLayoutService,
    private propsLayout: ConfigDesignLayoutService
  ) {
    try {
      const nomeEmpresa = this.route.snapshot.params['nomeEmpresa'];

      this.templateService.findTemplate(nomeEmpresa, () => this.verifyFinish())
      this.propsLayout.findPropsTemplate(nomeEmpresa, () => this.verifyFinish())
    } catch (e) {

    }
  }

  verifyFinish() {
    if (this.templateService.issetRootEmpresa() && this.propsLayout.issetPropsLayot()) {
      window.location.reload();
    }
  }
}
