import {PedidoProdutoEntity} from "./pedido-produto.entity";
import {ClienteEnderecoEntity} from "./cliente-endereco.entity";


export class VendaEntity {

  constructor(
    id?: string,
    produtos?: PedidoProdutoEntity[],
    total?: string | number,
    status?: string,
    subtotal?: number,
    taxaEntrega?: number,
    endereco?: ClienteEnderecoEntity
  ) {
    this.id = id
    this.produtos = Array.isArray(produtos) ? produtos : [];
    this.total = total
    this.status = status
    this.endereco = endereco
    this.subtotal = subtotal
    this.taxaEntrega = taxaEntrega
  }

  id: string | undefined;
  produtos: PedidoProdutoEntity[] = [];
  total: string | number | undefined;
  status: string | undefined;
  endereco: ClienteEnderecoEntity | undefined;
  subtotal: number | undefined;
  taxaEntrega: number | undefined;
}
