<layout-food1-v2 [showCarrinho]="false" [showMenu]="true">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card default-card">
          <div class="card-header">
            <h4 class="card-title">Meus dados</h4>
          </div>
          <div class="card-body">
            <form [formGroup]="form">
              <div class="row">
                <div class="col-6">
                  <label>Nome</label>
                  <input formControlName="nome" class="form-control">
                </div>
                <div class="col-6">
                  <label>Telefone</label>
                  <input formControlName="telefone" class="form-control" mask="00 00000-0000">
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <label>E-mail</label>
                  <input formControlName="email" class="form-control">
                </div>
              </div>
            </form>
          </div>
          <div class="card-body">
            <button (click)="saveDados()" class="btn btn-success">Salvar alterações</button>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
        <div class="card default-card">
          <div class="card-header">
            <h4 class="card-title">Meus endereços</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-12">
                <div class="card pointer">
                  <div class="card-body">
                    <h6 class="card-title">Novo endereço</h6>
                    <span class="card-text">Adicionar novo endereço</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mt-1" style="max-height: 50vh; overflow: auto">
              <div class="col-12" *ngFor="let endereco of table.controls.data.get(); let index = index">
                <card-endereco
                  [endereco]="endereco"
                  (afterDelete)="table.find()"
                  (clickEdit)="modalEditar.open(endereco)"
                ></card-endereco>
              </div>
            </div>
          </div>
          <div class="card-footer d-inline-flex justify-content-center align-items-center">
            <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
          </div>
        </div>
      </div>
    </div>
  </div>
</layout-food1-v2>


<modal-endereco #modalEditar (afterSave)="table.find()"></modal-endereco>
