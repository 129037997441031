import { Component, OnInit } from '@angular/core';
import {HttpService} from "../../../../services/http-service/http.service";
import {CLIENTE_HISTORICO} from "../../../../core/config/endpoints";
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../environments/environment";
import {SessionService} from "../../../../services/session-service/session.service";

@Component({
  selector: 'app-food1-historico',
  templateUrl: './food1-historico.component.html',
  styleUrls: ['./food1-historico.component.scss', '../../scss/index.scss']
})
export class Food1HistoricoComponent implements OnInit {

  table: CdkDynamicTable.tableClass;


  constructor(
    private http: HttpService, private createTable: CdkDynamicTableService,
    public session: SessionService
  ) {
    this.table = createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: CLIENTE_HISTORICO,
      },
      pagination: {
        sort: ['createdAt,asc']
      }
    })
    this.table.find();

    // this.http.get(CLIENTE_HISTORICO).subscribe(resp => {
    //   this.listPedidos = resp?.data || [];
    // })
  }

  ngOnInit(): void {
  }

  pipeDate(date:string) {
    return new Date(date).toLocaleString('pt-br')
  }

}
