<layout-food1-v2 [showCarrinho]="false" [showMenu]="false">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-8">
        <div class="card default-card">
          <h5 class="card-header">Histórico de pedidos</h5>
          <div class="card-body">
            <div class="row" *ngFor="let item of table.controls.data.dataSource">
              <div class="col-12">
                <div class="card default-card card-historico mt-1">
                  <div class="card-header">
                    <p><b>Numero pedido:</b> {{item.id}}</p>
                    <p><b>Data:</b> {{pipeDate(item.dataVenda)}}</p>
                    <p><b>Valor:</b> {{item?.subtotal}}</p>
                    <p><b>Status:</b> {{item?.status}}</p>
                  </div>
                  <div class="card-body">
                    <ul class="list-group">
                      <li class="list-group-item" *ngFor="let itemProduto of (item?.produtos || [])">
                        {{itemProduto?.quantidade}}X {{itemProduto?.nome}}

                        <ul class="list-group">
                          <li class="list-group-item" *ngFor="let itemProdutoComplemento of (itemProduto?.complementos || [])">
                            {{itemProdutoComplemento?.quantidade}}X {{itemProdutoComplemento?.nome}}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="card-footer">
                    <button class="btn btn-sm btn-success m-1">Repetir pedido</button>
                    <a [routerLink]="[session.baseRouter, 'acompanhar-pedido', item?.id]" class="btn btn-sm btn-secondary m-1">Ver pedido</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</layout-food1-v2>
