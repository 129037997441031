// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.delivzone.com.br/api/',
  apiUrlMock: '',
  develop: false,
  /**
   * esse atributo serve para orientar o roteamento do angular e não tem nenhuma
   * relação com as buscas am back-end.
   * Esse atributo será utilizado pela guard "LayoutGuard" onde "$linkEmpresa$" será
   * substituido pelo link da empresa e posto no "SessionService" para ser usado pelo sistema.
   * Só coloquei esse parametro aqui para facilitar o reconhecimento futuro.
   */
  baseRouter: '/client/$linkEmpresa$/',
  /**
   * essa parametro serve para saber o endereço de domínio do painel do sistema.
   */
  domainPainelUrl: 'http://painel.delivzone.com.br/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
