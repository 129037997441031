import {ProdutoEntity} from "./produto.entity";
import {PedidoProdutoComplementoEntity} from "./pedido-produto-complemento.entity";


export class PedidoProdutoEntity {
  constructor(
    id?: string | number,
    nome?: string,
    valor?: string | number,
    quantidade?: number,
    complementosPedido?: PedidoProdutoComplementoEntity[],
    produto?: Partial<ProdutoEntity>
  ) {
    this.id = id || ''
    this.nome = nome || '';
    this.valor = valor || 0
    this.quantidade = quantidade || 1
    this.complementosPedido = Array.isArray(complementosPedido) ? complementosPedido : []
    this.produto = produto;
  }

  id: string | number = ''
  nome: string = '';
  valor: string | number = 0
  quantidade: number = 1
  complementosPedido: PedidoProdutoComplementoEntity[] = []
  produto: Partial<ProdutoEntity> | undefined;
}
