import { Injectable } from '@angular/core';
import {replaceEndpointParams} from "../../helpers/endpoints-helpers";
import {ROOT_PROPS_TEMPLATE} from "../../core/config/endpoints";
import {HttpService} from "../http-service/http.service";
import {AbstractTemplateEntity, ImageTemplateEntity} from "../../entitys/template-entitys/abstract-template.entity";
import {TemplateFood1PropsEntity} from "../../entitys/template-entitys/template-food1.props.entity";

/**
 * service para lidar com modificações no layout, possívelmente fique obsoleto quando
 * novas implementações de layouts forem adicionadas. Então será necessário modificar
 * para lidar com dados diversos e/ou mapear todas as modificações dos layouts
 */

@Injectable({
  providedIn: 'root'
})
export class ConfigDesignLayoutService<T extends AbstractTemplateEntity = any> {

  modoEditor: boolean = false;

  propsTemplate?: T;

  constructor(private http: HttpService) {
    if (window.sessionStorage.getItem('modo-editor')) {
      this.modoEditor = Boolean(window.sessionStorage.getItem('modo-editor'));
    }
  }

  findPropsTemplate(empresaNome: string, callback: Function) {
    if (!this.issetPropsLayot()) {
      this.http.get(replaceEndpointParams(ROOT_PROPS_TEMPLATE, empresaNome)).subscribe(resp => {
        if (!resp.data) return;

        this.buildPropsTemplate(resp.data.nome, resp.data)
        this.savePropsLayout(this.propsTemplate);
        callback();
      });
    }
  }

  buildPropsTemplate(nome: string, data: any) {
    const TemplateClass = this.getTemplateClass(nome);
    this.propsTemplate = new TemplateClass(data);
    this.propsTemplate.getListUpdateVariablesCss().map(item => this.updateVarCss(item[0], item[1]))
    return true;
  }

  /**
   * metodo para verificação de existencia dos dados da empres
   * caso existam, ele irá setar automaticamente na propriedade "propsTemplate"
   */
  issetPropsLayot(): boolean {
    if (!!this.propsTemplate) {
      return true;
    }
    const propsTemplateString = window.sessionStorage.getItem('propsTemplate');

    if (!!propsTemplateString) {
      const propsTemplate = JSON.parse(propsTemplateString);
      return this.buildPropsTemplate(propsTemplate.nome, propsTemplate)
    }

    return false;
  }

  savePropsLayout(propsTemplate: any) {
    window.sessionStorage.setItem('propsTemplate', JSON.stringify(propsTemplate))
  }

  atualizarSessionStoragy() {
    window.sessionStorage.setItem('propsTemplate', JSON.stringify(this.propsTemplate))
  }

  changeModoEditor(status?: boolean) {
    if (status != undefined) {
      this.modoEditor = status;
    } else {
      this.modoEditor = !this.modoEditor;
    }

    if (this.modoEditor) {
      window.sessionStorage.setItem('modo-editor', 'true')
    } else {
      window.sessionStorage.removeItem('modo-editor')
    }
  }

  /**
   * Retorna a classe correta com base em algum parâmetro
   */
  private getTemplateClass(empresaNome: string): { new (data: any): T } {
    return TemplateFood1PropsEntity as any;

    // Exemplo simples: com base no nome da empresa, retorna a classe apropriada
    if (empresaNome === 'FOOD1') {
      return TemplateFood1PropsEntity as any;
    }

    throw new Error('Template não encontrado para a empresa: ' + empresaNome);
  }

  updateVarCss(name: string, value: string){
    document.documentElement.style.setProperty(name, value);
  }

  public getImgByPropsKey(propsKey: string): string {
    const img = this.propsTemplate?.mapImg.find(item => item.propsKey == propsKey);

    if (!!img) {
      return img.imageUrl
    }
    return '/assets/img/default-image.jpg';
  }

  public updateImgByPropsKey(newImg: ImageTemplateEntity): void {
    const indexImg = this.propsTemplate?.mapImg.findIndex(item => item.propsKey == newImg.propsKey);

    if (indexImg !== null && indexImg !== undefined) {
      if (indexImg > -1) {
        this.propsTemplate!.mapImg[indexImg] = newImg;
      } else {
        this.propsTemplate!.mapImg.push(newImg)
      }
    } else {
      if (!this.propsTemplate?.mapImg) {
        if (!this.propsTemplate) return;
        this.propsTemplate['mapImg'] = [];
        this.propsTemplate.mapImg.push(newImg)
      }
    }
  }
}
