import {Food1HomeComponent} from "../../../templates/template-food1/pages/food1-home/food1-home.component";
import {Route} from "@angular/router";
import {LayoutGuard} from "../../guards/layout.guard";
import {
  Food1CategoriasComponent
} from "../../../templates/template-food1/pages/food1-categorias/food1-categorias.component";
import {
  Food1CategoriasMainComponent
} from "../../../templates/template-food1/pages/food1-categorias-main/food1-categorias-main.component";
import {Food1ProdutoComponent} from "../../../templates/template-food1/pages/food1-produto/food1-produto.component";
import {Food1CarrinhoComponent} from "../../../templates/template-food1/pages/food1-carrinho/food1-carrinho.component";
import {
  Food1AcompanharPedidoComponent
} from "../../../templates/template-food1/pages/food1-acompanhar-pedido/food1-acompanhar-pedido.component";
import {StartEditorModeComponent} from "../../../shared-pages/start-editor-mode/start-editor-mode.component";
import {
  Food1HistoricoComponent
} from "../../../templates/template-food1/pages/food1-historico/food1-historico.component";
import {
  Food1MeusDadosComponent
} from "../../../templates/template-food1/pages/food1-meus-dados/food1-meus-dados.component";


export const TEMPLATE_ROUTERS: {[key:string]: Route } = {
  FOOD1: {
    path: 'client/:nomeEmpresa',
    canActivate: [ LayoutGuard ],
    children: [
      {path: '', component: Food1HomeComponent},
      {path: 'start-editor-mode/:token', component: StartEditorModeComponent},
      {path: 'carrinho', component: Food1CarrinhoComponent},
      {path: 'categotias', component: Food1CategoriasMainComponent},
      {path: 'categotias/:id', component: Food1CategoriasComponent},
      {path: 'produto/:id', component: Food1ProdutoComponent},
      {path: 'acompanhar-pedido/:id', component: Food1AcompanharPedidoComponent},
      {path: 'historico', component: Food1HistoricoComponent},
      {path: 'meus-dados', component: Food1MeusDadosComponent},
    ]
  },

}
