import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageContentComponent } from './image-content/image-content.component';
import { ContentConfigDesignLayoutComponent } from './content-config-design-layout/content-config-design-layout.component';
import {MatIconModule} from "@angular/material/icon";
import { EditorModeHoverComponent } from './editor-mode-hover/editor-mode-hover.component';
import { SidebarEditorModeComponent } from './sidebar-editor-mode/sidebar-editor-mode.component';



@NgModule({
    declarations: [
        ImageContentComponent,
        ContentConfigDesignLayoutComponent,
        EditorModeHoverComponent,
        SidebarEditorModeComponent
    ],
    exports: [
        ImageContentComponent,
        ContentConfigDesignLayoutComponent,
        EditorModeHoverComponent
    ],
  imports: [
    CommonModule,
    MatIconModule
  ]
})
export class UiModule { }
