import { Component, OnInit } from '@angular/core';
import {ClienteEnderecoEntity} from "../../../../entitys/cliente-endereco.entity";
import {CLIENTE, ENDERECO} from "../../../../core/config/endpoints";
import {HttpService} from "../../../../services/http-service/http.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {SessionService} from "../../../../services/session-service/session.service";
import Swal from "sweetalert2";
import {CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'app-food1-meus-dados',
  templateUrl: './food1-meus-dados.component.html',
  styleUrls: ['./food1-meus-dados.component.scss', '../../scss/index.scss']
})
export class Food1MeusDadosComponent implements OnInit {

  table: CdkDynamicTable.tableClass;

  form = new FormGroup({
    id: new FormControl('', [Validators.required]),
    nome: new FormControl('', [Validators.required]),
    email: new FormControl(''),
    telefone: new FormControl(''),
  })

  constructor(private http: HttpService, private session: SessionService, private createTable: CdkDynamicTableService) {
    this.form.patchValue({ id: this.session.user.id })

    this.table = createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: ENDERECO,
      },
      columns: [
        { name: 'nome', headerName: '' }
      ]
    })
    this.table.find()

    this.http.get(CLIENTE +'/'+ session.user.id).subscribe(resp => {
      this.form.patchValue(resp?.data)
    })
  }

  ngOnInit(): void {
  }

  saveDados() {
    if (this.form.invalid || !this.session.user.id) {
      this.form.markAllAsTouched();
      return;
    }

    const form = this.form.value;

    this.http.put(CLIENTE, form).subscribe(() => {
      Swal.fire({
        icon: 'success',
        title: 'Seus dados foram salvos',
        timer: 1000
      }).then()
    })
  }

}
