import {Component, Input, OnInit} from '@angular/core';
import {ProdutoEntity} from "../../../../../entitys/produto.entity";
import {
  ConfigDesignLayoutService
} from "../../../../../services/config-design-layout-service/config-design-layout.service";
import {PedidoProdutoEntity} from "../../../../../entitys/pedido-produto.entity";
import {CarrinhoService} from "../../../../../services/carrinho-service/carrinho.service";
import {TemplateFood1PropsEntity} from "../../../../../entitys/template-entitys/template-food1.props.entity";

@Component({
  selector: 'food1-produtos-carrinho1',
  templateUrl: './food1-produtos-carrinho1.component.html',
  styleUrls: ['./food1-produtos-carrinho1.component.scss', './../../../scss/index.scss']
})
export class Food1ProdutosCarrinho1Component implements OnInit {

  @Input() pedidoProduto!: PedidoProdutoEntity;
  @Input() indexPedidoProduto: number = -1;


  constructor(public config: ConfigDesignLayoutService<TemplateFood1PropsEntity>, public carrinho: CarrinhoService) { }

  ngOnInit(): void {
  }

  changeQuantidadeProduto(quantidade: number) {
    if (this.indexPedidoProduto >= 0 && quantidade > 0) {
      this.carrinho.updateQuantidadeProduto(this.indexPedidoProduto, quantidade)
    }
  }
}
